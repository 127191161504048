import React, { useState, useRef, useEffect } from "react";
import Header from './Header';
import Summary from './Summary';
import CopyrightYear from './Copyright';
import LoadingScreen from './LoadingScreen';
import { useNavigate } from 'react-router-dom';

import { useSpring, animated } from "react-spring";
import { useAppContext } from "./AppContext";
import { CSSTransition } from "react-transition-group";
import "tailwindcss/tailwind.css";
import "./ButtonComponent.css";

import capSvg from "./assets/cap.svg";
import houseSvg from "./assets/house.svg";
import foodSvg from "./assets/food.svg";
import travelSvg from "./assets/travel.svg";
import walletSvg from "./assets/wallet.svg";
import incomeSvg from "./assets/income.svg";

const Expenses = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 250);
  });

  const navigate = useNavigate();

  const navigateToResults = () => {
    navigate('/results');
  };

  const [hiddenButtonsVisible, setHiddenButtonsVisible] = useState(false);
  const [travelButtonsVisible, setTravelButtonsVisible] = useState(false);
  const [travelButtonsVisible2, setTravelButtonsVisible2] = useState(false);
  const [incomeButtonVisible, setIncomeButtonVisible] = useState(false);
  const [incomeButtonVisible2, setIncomeButtonVisible2] = useState(false);

  const [additionalTextVisible, setAdditionalTextVisible] = useState(false);
  const [travelTextVisible, setTravelTextVisible] = useState(false);
  const [travelTextVisible2, setTravelTextVisible2] = useState(false);

  const [additionalInputVisible, setAdditionalInputVisible] = useState(false);
  const [travelInputVisible, setTravelInputVisible] = useState(false);
  const [travelInputVisible2, setTravelInputVisible2] = useState(false);
  const [incomeInputVisible, setIncomeInputVisible] = useState(false);

  const [selectedButtons, setSelectedButtons] = useState({});

  const hiddenButtonsRef = useRef(null);
  const travelButtonsRef = useRef(null);
  const travelButtonsRef2 = useRef(null);
  const incomeInputRef = useRef(null);
  const incomeButtonRef = useRef(null);
  const incomeButtonRef2 = useRef(null);

  const toggleVisibility = () => {
    setHiddenButtonsVisible(!hiddenButtonsVisible);
    setAdditionalTextVisible(!additionalTextVisible);
    setAdditionalInputVisible(!additionalInputVisible);
  };

  const toggleTravelButtonsVisibility = () => {
    setTravelButtonsVisible(!travelButtonsVisible);
    setTravelTextVisible(!travelTextVisible);
    setTravelInputVisible(!travelInputVisible);
  };

  const toggleTravelButtonsVisibility2 = () => {
    setTravelButtonsVisible2(!travelButtonsVisible2);
    setTravelTextVisible2(!travelTextVisible2);
    setTravelInputVisible2(!travelInputVisible2);
  };

  const toggleIncomeInputVisibility = () => {
    setIncomeInputVisible(!incomeInputVisible);
  };

  const toggleIncomeButtonVisibility = () => {
    setIncomeButtonVisible(!incomeButtonVisible);
  };

  const toggleIncomeButtonVisibility2 = () => {
    setIncomeButtonVisible2(!incomeButtonVisible2);
  };

  const hideButtons = () => {
    setHiddenButtonsVisible(false);
    setAdditionalTextVisible(false);
    setAdditionalInputVisible(false);
  };

  const hideButtonsTravel = () => {
    setTravelButtonsVisible2(false);
    setTravelTextVisible2(false);
    setTravelInputVisible2(false);
  };

  const hideButtonsTravel2 = () => {
    setTravelButtonsVisible(false);
    setTravelInputVisible(false);
    setTravelTextVisible(false);
  };

  const hideInputIncome = () => {
    setIncomeInputVisible(false);
  };

  const hideButtonsIncome = () => {
    setIncomeButtonVisible(false);
  };

  const hideButtonsIncome2 = () => {
    setIncomeButtonVisible2(false);
  };

  const { calculatedValues, updateCalculatedValue } = useAppContext();
  
  const getButtonValue = (category, buttonId) => {
    const buttonValues = {
      "educationId1": 10000,
      "educationId2": (10000 + 15000) / 2,
      "educationId3": (15000 + 20000) / 2,
      "educationId4": (20000 + 25000) / 2,
      "educationId5": (25000 + 30000) / 2,
      "educationId6": (30000 + 35000) / 2,
      "foodId1": (350 + 400) / 2,
      "foodId2": (500 + 600) / 2,
      "foodId3": (500 + 800) / 2,
      "food2Id1": (60 + 140) / 2,
      "food2Id2": (160 + 250) / 2,
      "food2Id3": (300 + 500) / 2,
      "travelAltId1": (30 + 60) / 2,
      "travelAltId2": (90 + 120) / 2,
      "travelAltId3": (156),
      "travelAlt2Id1": (250 + 400) / 2,
      "travelAlt2Id2": (400 + 500) / 2,
      "travelAlt2Id3": (600 + 900) / 2,
      "personalId1": (30 + 80) / 2,
      "personalId2": (120 + 180) / 2,
      "personalId3": (250 + 300) / 2,
      "personalAltId1": (50 + 180) / 2,
      "personalAltId2": (200 + 300) / 2,
      "personalAltId3": (350 + 600) / 2,
      "personalAlt2Id1": (50 + 150) / 2,
      "personalAlt2Id2": (200 + 350) / 2,
      "personalAlt2Id3": (350 + 500) / 2,
      "housingId1": 900,
      "housingId2": 1100,
      "housingAltId1": (450 + 700) / 2,
      "housingAltId2": (800 + 1000) / 2,
      "housingAltId3": (1200 + 1700) / 2,
      "housingAltId4": (1200 + 1700) / 2,
      "housingAltId5": (1500 + 2000) / 2,
      "housingAltId6": (2000 + 2700) / 2,
      "housingAlt2Id1": 0,
      "housingAlt2Id2": (50 + 70) / 2,
      "housingAlt2Id3": (80 + 150) / 2,
      "housingAlt3Id1": 0,
      "housingAlt3Id2": (20 + 30) / 2,
      "housingAlt3Id3": (50 + 100) / 2,
      "incomeAlt2Id1": (20),
      "incomeAlt2Id2": (40),
      "incomeAlt4Id1": (15+20) / 2,
      "incomeAlt4Id2": (20+30) / 2,
    };
  
    return buttonValues[buttonId] || 0;
  };

  const handleButtonClick = (section, buttonId) => {
    setSelectedButtons((prevSelected) => {
      const newSelected = { ...prevSelected };

      if (section === "education") {
        calculatedValues.educationInput = 0;
        setInputEducationTransitionActive(false); // Reset submitPressed to false
      } else if (section === "housing") {
        calculatedValues.housingInput = 0;
        calculatedValues.housingAlt = 0;
        calculatedValues.housingAlt2 = 0;
        calculatedValues.housingAlt3 = 0;
        setTransitionActive(false);
        newSelected["housingAlt"] = null;
        newSelected["housingAlt2"] = null;
        newSelected["housingAlt3"] = null;
      } else if (section === "travel") {
        calculatedValues.travelInput = 0;
        calculatedValues.travelInput2 = 0;
        calculatedValues.travelAlt = 0;
        calculatedValues.travelAlt2 = 0;
        setInputTravelTransitionActive(false);
        setInputTravel2TransitionActive(false);
        newSelected["travelAlt"] = null;
        newSelected["travelAlt2"] = null;
      } else if (section === "travelAlt"){
        calculatedValues.travelInput = 0;
        setInputTravelTransitionActive(false);
      } else if (section === "travelAlt2"){
        calculatedValues.travelInput = 0;
        setInputTravel2TransitionActive(false);
      } else if (section === "income") {
        setInputIncomeTransitionActive(false);
        calculatedValues.incomeAlt = 0;
      } else if (section === "incomeAlt3") {
        newSelected["incomeAlt4"] = null;
        calculatedValues.incomeAlt4 = 0;
      } else if (section === "incomeAlt") {
        newSelected["incomeAlt2"] = null;
        calculatedValues.incomeAlt2 = 0;
      }

      if (submitTravelPressed) {
        calculatedValues.travel = 0;
        calculatedValues.travelAlt = 0;
        calculatedValues.travelAlt2 = 0;
        setTravelSubmitPressed(false);
      }

      if (newSelected[section] === buttonId) {
        const buttonValue = getButtonValue(section, buttonId);
        updateCalculatedValue(section, -buttonValue); // Subtract the value
        newSelected[section] = null; // Deselect the button
      } else {
        // Deselect the previously selected button
        const prevSelectedButton = newSelected[section];
        if (prevSelectedButton !== null) {
          const prevButtonValue = getButtonValue(section, prevSelectedButton);
          updateCalculatedValue(section, -prevButtonValue); // Subtract the value
        }

        // Select the new button
        newSelected[section] = buttonId;
        const buttonValue = getButtonValue(section, buttonId);
        updateCalculatedValue(section, buttonValue); // Add the value
      }
      
      return newSelected;
    });
  };

  useEffect(() => {
    if (hiddenButtonsVisible && hiddenButtonsRef.current) {
      hiddenButtonsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [hiddenButtonsVisible]);

  useEffect(() => {
    if (travelButtonsVisible && travelButtonsRef.current) {
      travelButtonsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [travelButtonsVisible]);

  useEffect(() => {
    if (travelButtonsVisible2 && travelButtonsRef2.current) {
      travelButtonsRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [travelButtonsVisible2]);

  useEffect(() => {
    if (incomeInputVisible && incomeInputRef.current) {
      incomeInputRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [incomeInputVisible]);

  useEffect(() => {
    if (incomeButtonVisible && incomeButtonRef.current) {
      incomeButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [incomeButtonVisible]);

  useEffect(() => {
    if (incomeButtonVisible2 && incomeButtonRef2.current) {
      incomeButtonRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [incomeButtonVisible2]);



// -------------------------- Education Input --------------------------
//const [submitEducationPressed, setEducationSubmitPressed] = useState(false);
  const [customEducationValue, setCustomEducationValue] = useState(0);
  const [previousCustomEducationValue, setPreviousCustomEducationValue] = useState(0);

  const handleEducationInputChange = (e) => {
    setCustomEducationValue(parseInt(e.target.value) || 0);
  };

  const handleCustomEducationSubmit = () => {
    // Add logic to handle the custom education value
    if (customEducationValue === 0) {
      setCustomEducationValue(0);
    } else {
      setInputEducationTransitionActive(true);
      updateCalculatedValue("education", -calculatedValues.education);
      updateCalculatedValue("educationInput", customEducationValue);
      setPreviousCustomEducationValue(customEducationValue);
      setSelectedButtons((prevSelected) => ({ ...prevSelected, education: null }));
      setCustomEducationValue(0);
    }
    // Add the new custom education value
  
    // Update state variables
  };

// ------------------------------------------------------------------
// -------------------------- Housing Input --------------------------
const [customHousingValue, setCustomHousingValue] = useState(0);
const [previousCustomHousingValue, setPreviousCustomHousingValue] = useState(0);

const handleHousingInputChange = (e) => {
  setCustomHousingValue(parseInt(e.target.value) || 0);
};

const handleCustomHousingSubmit = () => {
  // Add logic to handle the custom education value
  if (customHousingValue === 0) {
    setCustomHousingValue(0);
  } else {
    setTransitionActive(true);
    updateCalculatedValue("housingInput", customHousingValue);
    // Update state variables
    setPreviousCustomHousingValue(customHousingValue);
    setCustomHousingValue(0);
  }
};

const [isInputEducationTransitionActive, setInputEducationTransitionActive] = useState(false);

const handleDeletationEducationSubmit = () => {
  updateCalculatedValue("educationInput", -calculatedValues.educationInput);
  setInputEducationTransitionActive(false);
}

const [isInputTravelTransitionActive, setInputTravelTransitionActive] = useState(false);

const handleDeletationTravelSubmit = () => {
  updateCalculatedValue("travelInput", -calculatedValues.travelInput);
  setInputTravelTransitionActive(false);
}

const [isInputTravel2TransitionActive, setInputTravel2TransitionActive] = useState(false);

const handleDeletationTravel2Submit = () => {
  updateCalculatedValue("travelInput2", -calculatedValues.travelInput2);
  setInputTravel2TransitionActive(false);
}

const [isTransitionActive, setTransitionActive] = useState(false);

const handleDeletationHousingSubmit = () => {
  updateCalculatedValue("housingInput", -calculatedValues.housingInput);
  setTransitionActive(false);
}

const [isInputIncomeTransitionActive, setInputIncomeTransitionActive] = useState(false);

const handleDeletationIncomeSubmit = () => {
  updateCalculatedValue("incomeAlt", -calculatedValues.incomeAlt);
  setInputIncomeTransitionActive(false);
}

const [isInputIncome2TransitionActive, setInputIncome2TransitionActive] = useState(false);

const handleDeletationIncome2Submit = () => {
  updateCalculatedValue("incomeAlt5", -calculatedValues.incomeAlt5);
  setInputIncome2TransitionActive(false);
}

const [isInputIncome3TransitionActive, setInputIncome3TransitionActive] = useState(false);

const handleDeletationIncome3Submit = () => {
  updateCalculatedValue("incomeAlt6", -calculatedValues.incomeAlt6);
  setInputIncome3TransitionActive(false);
}

// ------------------------------------------------------------------
// -------------------------- Travel Input --------------------------

  const [submitTravelPressed, setTravelSubmitPressed] = useState(false);
  const [customTravelValue, setCustomTravelValue] = useState(0);
  const [previousCustomTravelValue, setPreviousCustomTravelValue] = useState(0);

  const handleTravelInputChange = (e) => {
    setCustomTravelValue(parseInt(e.target.value) || 0);
  };

  const handleCustomTravelSubmit = () => {
    if(customTravelValue === 0) {
      setCustomTravelValue(0);
    } else {
      setInputTravelTransitionActive(true);
      updateCalculatedValue("travelInput", customTravelValue);
      setPreviousCustomTravelValue(customTravelValue);
      setCustomTravelValue(0);
    }
  };


  const [customTravel2Value, setCustomTravel2Value] = useState(0);
  const [previousCustomTravel2Value, setPreviousCustomTravel2Value] = useState(0);

  const handleTravel2InputChange = (e) => {
    setCustomTravel2Value(parseInt(e.target.value) || 0);
  };

  const handleCustomTravel2Submit = () => {
    if(customTravel2Value === 0) {
      setCustomTravel2Value(0);
    } else {
      setInputTravel2TransitionActive(true);
      updateCalculatedValue("travelInput2", customTravel2Value);
      setPreviousCustomTravel2Value(customTravel2Value);
      setCustomTravel2Value(0);
    }
  };

// ------------------------------------------------------------------
// -------------------------- Income Input --------------------------

//const [submitIncomePressed, setIncomeSubmitPressed] = useState(false);
const [customIncomeValue, setCustomIncomeValue] = useState(0);
const [previousCustomIncomeValue, setPreviousCustomIncomeValue] = useState(0);

const handleIncomeInputChange = (e) => {
  setCustomIncomeValue(parseInt(e.target.value) || 0);
};

const handleCustomIncomeSubmit = () => {
  // Add logic to handle the custom education value
  if (customIncomeValue === 0) {
    // Subtract the previous custom education value
    setCustomIncomeValue(0);
  } else {
    setInputIncomeTransitionActive(true);
    updateCalculatedValue("incomeAlt", customIncomeValue);
    setPreviousCustomIncomeValue(customIncomeValue);
    setCustomIncomeValue(0);
  }
};

// ------------------------------------------------------------------
//const [submitIncome2Pressed, setIncome2SubmitPressed] = useState(false);
const [customIncome2Value, setCustomIncome2Value] = useState(0);
const [previousCustomIncome2Value, setPreviousCustomIncome2Value] = useState(0);

const handleIncome2InputChange = (e) => {
  setCustomIncome2Value(parseInt(e.target.value) || 0);
};

const handleCustomIncome2Submit = () => {
  // Add logic to handle the custom education value
  if (customIncome2Value === 0) {
    // Subtract the previous custom education value
    setCustomIncome2Value(0);
  } else {
    setInputIncome2TransitionActive(true);
    updateCalculatedValue("incomeAlt5", customIncome2Value);
    setPreviousCustomIncome2Value(customIncome2Value);
    setCustomIncome2Value(0);
  }
};

// ------------------------------------------------------------------
//const [submitIncome3Pressed, setIncome3SubmitPressed] = useState(false);
const [customIncome3Value, setCustomIncome3Value] = useState(0);
const [previousCustomIncome3Value, setPreviousCustomIncome3Value] = useState(0);

const handleIncome3InputChange = (e) => {
  setCustomIncome3Value(parseInt(e.target.value) || 0);
};

const handleCustomIncome3Submit = () => {
  // Add logic to handle the custom education value
  if (customIncome3Value === 0) {
    // Subtract the previous custom education value
    setCustomIncome3Value(0);
  } else {
    setInputIncome3TransitionActive(true);
    updateCalculatedValue("incomeAlt6", customIncome3Value);
    setPreviousCustomIncome3Value(customIncome3Value);
    setCustomIncome3Value(0);
  }
};

  //---------------------------- Education Summary ----------------------------

  const progressEducationBarRef = useRef(null);
  const maxValueEducation = 50000;
  const valueEducation = calculatedValues.education + calculatedValues.educationInput || 0;

  const { educationValue } = useSpring({
    from: { educationValue: 0 },
    to: { educationValue: parseInt(calculatedValues.education + calculatedValues.educationInput, 10) || 0 },
    reset: false,
  });

  const [totalEducationValue, setTotalEducationValue] = useState(0);
  
  useEffect(() => {
    setTotalEducationValue(valueEducation + totalEducationValue);
  }, [valueEducation]);

  useEffect(() => {
    const progressEducationBar = progressEducationBarRef.current;
    const percentage = (valueEducation / maxValueEducation) * 100 + "%";
    progressEducationBar.style.width = percentage;

    const color =
      valueEducation === maxValueEducation
        ? "red"
        : calculateColorEducation(valueEducation, maxValueEducation);
        progressEducationBar.style.backgroundColor = color;
  }, [calculatedValues.education, valueEducation]);

  const calculateColorEducation = (valueEducation, max) => {
    const percentage = (valueEducation / max) * 100;
    const green = 255 - Math.round(percentage * 2.55);
    const red = Math.round(percentage * 2.55);
    return `rgb(${red}, ${green}, 0)`;
  };

//--------------------------------------------------------------
//---------------------------- Housing Summary ----------------------------

const progressHousingBarRef = useRef(null);
const maxValueHousing = 3000;
const valueHousing = (calculatedValues.housing + calculatedValues.housingAlt + calculatedValues.housingAlt2 + calculatedValues.housingAlt3 + calculatedValues.housingInput) || 0;

const { housingValue } = useSpring({
  from: { housingValue: 0 },
  to: { housingValue: parseInt(calculatedValues.housing + calculatedValues.housingAlt + calculatedValues.housingAlt2 + calculatedValues.housingAlt3 + calculatedValues.housingInput, 10) || 0 },
  reset: false,
});

const [totalHousingValue, setTotalHousingValue] = useState(0);

useEffect(() => {
  setTotalHousingValue(valueHousing + totalHousingValue);
}, [valueHousing]);

useEffect(() => {
  const progressHousingBar = progressHousingBarRef.current;
  const percentage = (valueHousing/ maxValueHousing) * 100 + "%";
  progressHousingBar.style.width = percentage;

  const color =
    valueHousing === maxValueHousing
      ? "red"
      : calculateColorHousing(valueHousing, maxValueHousing);
      progressHousingBar.style.backgroundColor = color;
}, [calculatedValues.housing, valueHousing]);

const calculateColorHousing = (valueHousing, max) => {
  const percentage = (valueHousing / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Food Summary ----------------------------

const progressFoodBarRef = useRef(null);
const maxValueFood = 1000;
const valueFood = (calculatedValues.food + calculatedValues.food2) || 0;

const { foodValue } = useSpring({
  from: { foodValue: 0 },
  to: { foodValue: parseInt(calculatedValues.food + calculatedValues.food2, 10) || 0 },
  reset: false,
});

const [totalFoodValue, setTotalFoodValue] = useState(0);

useEffect(() => {
  setTotalFoodValue(valueFood + totalFoodValue);
}, [valueFood]);

useEffect(() => {
  const progressFoodBar = progressFoodBarRef.current;
  const percentage = (valueFood / maxValueFood) * 100 + "%";
  progressFoodBar.style.width = percentage;

  const color =
    valueFood === maxValueFood
      ? "red"
      : calculateColorFood(valueFood, maxValueFood);
      progressFoodBar.style.backgroundColor = color;
}, [calculatedValues.food, valueFood]);

const calculateColorFood = (valueFood, max) => {
  const percentage = (valueFood / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Travel Summary----------------------------
  
const progressTravelBarRef = useRef(null);
const maxValueTravel = 1500;
const valueTravel = (calculatedValues.travel + calculatedValues.travelAlt + calculatedValues.travelAlt2 + calculatedValues.travelInput + calculatedValues.travelInput2) || 0;

const { travelValue } = useSpring({
  from: { travelValue: 0 },
  to: { travelValue: parseInt(calculatedValues.travel + calculatedValues.travelAlt + calculatedValues.travelAlt2 + calculatedValues.travelInput + calculatedValues.travelInput2, 10) || 0 },
  reset: false,
});

const [totalTravelValue, setTotalTravelValue] = useState(0);

useEffect(() => {
  setTotalTravelValue(valueTravel + totalTravelValue);
}, [valueTravel]);

useEffect(() => {
  const progressTravelBar = progressTravelBarRef.current;
  const percentage = (valueTravel / maxValueTravel) * 100 + "%";
  progressTravelBar.style.width = percentage;

  const color =
    valueTravel === maxValueTravel
      ? "red"
      : calculateColorTravel(valueTravel, maxValueTravel);
      progressTravelBar.style.backgroundColor = color;
}, [calculatedValues.travel, valueTravel]);

const calculateColorTravel = (valueTravel, max) => {
  const percentage = (valueTravel / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};


//--------------------------------------------------------------
//---------------------------- Personal Summary ----------------------------

const progressPersonalBarRef = useRef(null);
const maxValuePersonal = 1500;
const valuePersonal = (calculatedValues.personal + calculatedValues.personalAlt + calculatedValues.personalAlt2) || 0;

const { personalValue } = useSpring({
  from: { personalValue: 0 },
  to: { personalValue: parseInt(calculatedValues.personal + calculatedValues.personalAlt + calculatedValues.personalAlt2, 10) || 0 },
  reset: false,
});

const [totalPersonalValue, setTotalPersonalValue] = useState(0);

useEffect(() => {
  setTotalPersonalValue(valuePersonal + totalPersonalValue);
}, [valuePersonal]);

useEffect(() => {
  const progressPersonalBar = progressPersonalBarRef.current;
  const percentage = (valuePersonal / maxValuePersonal) * 100 + "%";
  progressPersonalBar.style.width = percentage;

  const color =
    valuePersonal === maxValuePersonal
      ? "red"
      : calculateColorPersonal(valuePersonal, maxValuePersonal);
      progressPersonalBar.style.backgroundColor = color;
}, [calculatedValues.personal, valuePersonal]);

const calculateColorPersonal = (valuePersonal, max) => {
  const percentage = (valuePersonal / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Income Summary ----------------------------
const progressIncomeBarRef = useRef(null);
const maxValueIncome = 5000;
const valueIncome =  (calculatedValues.incomeAlt + (calculatedValues.incomeAlt2*calculatedValues.incomeAlt4)*4 + calculatedValues.incomeAlt5 + calculatedValues.incomeAlt6) || 0;

const { incomeValue } = useSpring({
  from: { incomeValue: 0 },
  to: { incomeValue: parseInt(calculatedValues.incomeAlt + (calculatedValues.incomeAlt2*calculatedValues.incomeAlt4)*4 + calculatedValues.incomeAlt5 + calculatedValues.incomeAlt6, 10) || 0 },
  reset: false,
});

const [totalIncomeValue, setTotalIncomeValue] = useState(0);

useEffect(() => {
  setTotalIncomeValue(valueIncome + totalIncomeValue);
}, [valueIncome]);

useEffect(() => {
  const progressIncomeBar = progressIncomeBarRef.current;
  const percentage = (valueIncome/ maxValueIncome) * 100 + "%";
  progressIncomeBar.style.width = percentage;

  const color =
    valueIncome === maxValueIncome
      ? "green"
      : calculateColorIncome(valueIncome, maxValueIncome);
      progressIncomeBar.style.backgroundColor = color;
}, [valueIncome]); //

const calculateColorIncome = (valueIncome, max) => {
  const percentage = (valueIncome / max) * 100;
  const red = 255 - Math.round(percentage * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Total Summary ----------------------------
const progressTotalBarRef = useRef(null);
const maxValueTotal = 50000;
const valueTotal = valueEducation + valueFood + valueTravel + valuePersonal + valueHousing || 0;

const { totalValue } = useSpring({
  from: { totalValue: 0 },
  to: { totalValue: parseInt(valueTotal, 10) || 0 },
  reset: false,
});

const [totalTotalValue, setTotalTotalValue] = useState(0);

useEffect(() => {
  setTotalTotalValue(valueTotal + totalTotalValue);
}, [valueTotal]);

useEffect(() => {
  const progressTotalBar = progressTotalBarRef.current;
  const percentage = (valueTotal/ maxValueTotal) * 100 + "%";
  progressTotalBar.style.width = percentage;

  const color =
    valueTotal === maxValueTotal
      ? "red"
      : calculateColorTotal(valueTotal, maxValueTotal);
      progressTotalBar.style.backgroundColor = color;
}, [valueTotal]); //

const calculateColorTotal = (valueTotal, max) => {
  const percentage = (valueTotal / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};
//--------------------------------------------------------------
//---------------------------- Total Income Summary ----------------------------

const progressIncomeTotalBarRef = useRef(null);

useEffect(() => {
  const progressIncomeTotalBar = progressIncomeTotalBarRef.current;
  const percentage = (valueIncome/ maxValueIncome) * 100 + "%";
  progressIncomeTotalBar.style.width = percentage;

  const color =
    valueIncome === maxValueIncome
      ? "green"
      : calculateColorIncomeTotal(valueIncome, maxValueIncome);
      progressIncomeTotalBar.style.backgroundColor = color;
}, [valueIncome]); //

const calculateColorIncomeTotal = (valueIncome, max) => {
  const percentage = (valueIncome / max) * 100;
  const red = 255 - Math.round(percentage * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------

const shouldRenderSummary =
window.innerWidth >= 640 && window.innerHeight >= 670;




//-----------

  return (
    <div>
      <>{loading && <LoadingScreen />}
      </>
      
      <div className=" flex flex-col w-full p-8 mx-auto mt-[30px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <h1 className="sm:text-4xl md:text-3xl lg:text-4xl">
          Kanada'da Eğitim Bütçesi Hesap Makinesi
        </h1>
        <p className="flex-grow mt-2 text-left pt-[20px]">
          Bu hesap makinesi size bir fikir vermesi açısından oluşturulmuştur 
          ve sizin Kanada'daki şartlarınız net olarak ortaya konmadığı için 
          net bir sonuç vermemektedir. Tüm masraflar yaşayacağınız eyalet ve 
          şehre göre değişiklik gösterebilir. Verilen fiyatlar Kanada ortalaması 
          baz alınarak belirlenmiştir.  
        </p>

        <p className="flex-grow mt-2 text-left pt-[20px]">
          <b>Hesap Makinesini Nasıl Kullanırım?</b>
          <ol className="ml-8 text-[14px]" style={{listStyleType: 'decimal'}}>
            <li className="mt-1">Sunulan seçenekleri inceleyerek durumunuza en uygun olanı işaretleyebilirsiniz.</li>
            <li className="mt-1">Seçiminizi kaldırmak için aynı yere tekrar tıklayabilirsiniz.</li>
            <li className="mt-1">Belirtmek istediğiniz miktar seçeneklerde yoksa, aynı kategoride yer alan boşluğa istediğiniz miktarı girebilirsiniz.</li>
            <li className="mt-1">Seçimlerinizi tamamladığınızda son durumu görmek için sayfanın sağ altında bulunan "Bitir", mobilde sayfanın en altında bulunan "Bitir" tuşuna basabilirsiniz.</li>
            <li className="mt-1">"Finansal Durum Özeti" tablosuna geldiğinizde gelir ve giderlerinizi tekrar inceleyebilirsiniz. Değişiklik yapmak isterseniz sayfanın altında bulunan farklı kategoriler için ayrılmış boşluklara istediğiniz değerleri girip "+" tuşuna basarak değerleri değiştirebilirsiniz.</li>
          </ol>
        </p>
      </div>

      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={capSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Eğitim
          </h1>
          <span className="inline-block mt-4 ">
            Okulunuzun bir yıllık ücreti ne kadar?
          </span>
        </div>

        <div class="flex flex-col items-center">
          <div class="flex flex-wrap">
            <button
              onClick={() => handleButtonClick("education", "educationId1")}
              class={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $10.000 CAD'dan az
            </button>
            <button
              onClick={() => {
                handleButtonClick("education", "educationId2");
              }}
              class={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $10.000 CAD - $15.000 CAD
            </button>
            <button
              onClick={() => {
                handleButtonClick("education", "educationId3");
              }}
              class={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $15.000 CAD - $20.000 CAD
            </button>
          </div>


          <div class="flex flex-wrap mt-4">
            <button
              onClick={() => {
                handleButtonClick("education", "educationId4");
              }}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId4" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $20.000 CAD - $25.000 CAD
            </button>
            <button
              onClick={() => {
                handleButtonClick("education", "educationId5");
              }}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId5" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $25.000 CAD - $30.000 CAD
            </button>
            <button
              onClick={() => {
                handleButtonClick("education", "educationId6");
              }}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.education === "educationId6" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              $30.000 CAD - $35.000 CAD
            </button>
          </div>

          <div class="flex justify-start">
            <span className="inline-block mt-8 ">
              Net yıllık ücreti biliyorsanız giriniz:
            </span>
          </div>

          <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start">
            <input
              type="text"
              class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
              placeholder="Okul Ücreti $CAD"
              value={customEducationValue}
              onChange={handleEducationInputChange}
            />
            <button
              onClick={() => {
                handleCustomEducationSubmit();
              }}
              className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md -bold sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-red-700 hover:text-white border-pathway-red"
            >
              Ekle
            </button>
            
            <div class="flex justify-start mt-2">
              <p
                className={`flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                  isInputEducationTransitionActive
                    ? "transition-transform-active"
                    : "hidden"
                }`}
                style={{  Size: "12px" }} // Set the   size to be the same as the other elements
              >
                  ${calculatedValues.educationInput >= 1000
                    ? (calculatedValues.educationInput / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.educationInput}CAD
              </p>
              <button
                onClick={() => {
                  handleDeletationEducationSubmit();
                }}
                className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  -bold text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                  isInputEducationTransitionActive
                    ? "transition-transform-active-button"
                    : "hidden"
                }`}
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Eğitim
        </h1>

        <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {educationValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressEducationBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>


      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start   sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={houseSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Konaklama
          </h1>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-2 ">
            Konaklamanızı nerede planlıyorsunuz?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => {
              handleButtonClick("housing", "housingId1");
              hideButtons();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.housing === "housingId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Aile Yanı Konaklama
          </button>
          <button
            onClick={() => {
              handleButtonClick("housing", "housingId2");
              hideButtons();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.housing === "housingId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Kampüs İçi Konaklama
          </button>
          <button
            onClick={() => {
              handleButtonClick("housing", "housingId3");
              toggleVisibility();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.housing === "housingId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Kampüs Dışı Ev/Oda Kiralama
          </button>
        </div>

        <CSSTransition
          in={additionalTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="  sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px]    mt-4">
              Oda ve ev fiyatları şehrin gelişmişlik seviyelerine göre kategorize edilmiştir, fiyatlar eyalet ve şehire
              göre değişiklik gösterebilmektedir.
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={hiddenButtonsVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4" ref={hiddenButtonsRef}>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Oda Kiralama <br></br> $450-$700CAD/Aylık
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Oda Kiralama <br></br> $800-$1000CAD/Aylık
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId3")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Oda Kiralama <br></br> $1200-$1700CAD/Aylık
            </button>
          </div>
        </CSSTransition>
        
        <CSSTransition
          in={hiddenButtonsVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-2" ref={hiddenButtonsRef}>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId4")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId4" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ev Kiralama <br></br> $1200-$1700CAD/Aylık
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId5")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId5" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ev Kiralama <br></br> $1500-$2000CAD/Aylık
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt", "housingAltId6")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt === "housingAltId6" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ev Kiralama <br></br> $2000-$2700CAD/Aylık
            </button>
          </div>
        </CSSTransition>

        <CSSTransition
          in={additionalTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="mt-8 ">
              Aylık ev masraflarınız ne kadar?
            </span>

            <span className="  sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px]    mt-4">
              *Kanada'da ısınma, su ve elektrik gibi giderler zaman zaman kiraya
              dahil edilmektedir.
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={hiddenButtonsVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4">
            <button
              onClick={() => handleButtonClick("housingAlt2", "housingAlt2Id1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt2 === "housingAlt2Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Kiraya Dahil
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt2", "housingAlt2Id2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt2 === "housingAlt2Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Paylaşımlı <br></br> $50-$70CAD
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt2", "housingAlt2Id3")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt2 === "housingAlt2Id3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Tamamı <br></br> $80-$150CAD
            </button>
          </div>
        </CSSTransition>

        <CSSTransition
          in={additionalTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="mt-8 ">
              İnternet ödeyecek misiniz?
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={hiddenButtonsVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4">
            <button
              onClick={() => handleButtonClick("housingAlt3", "housingAlt3Id1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt3 === "housingAlt3Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Kiraya Dahil
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt3", "housingAlt3Id2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt3 === "housingAlt3Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Paylaşımlı <br></br> $20-$30CAD
            </button>
            <button
              onClick={() => handleButtonClick("housingAlt3", "housingAlt3Id3")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.housingAlt3 === "housingAlt3Id3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Tamamı <br></br> $50-$100CAD
            </button>
          </div>
        </CSSTransition>

        <CSSTransition
          in={additionalTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="mt-8 ">
              Konaklama ile ilgili aylık başka harcamalarınız olacak mı?
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={additionalInputVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start">
            <input
              type="text"
              class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
              placeholder="Diğer Harcamalar $CAD"
              value={customHousingValue}
              onChange={handleHousingInputChange}
            />
            <button
              onClick={() => {
                handleCustomHousingSubmit();
              }}
              className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
              >
              Ekle
            </button>

            <div class="flex justify-start mt-2">
              <p
                className={`flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                    isTransitionActive 
                      ? "transition-transform-active" 
                      : "hidden"
                }`}
                style={{  Size: "12px" }}
              >
                ${calculatedValues.housingInput >= 1000
                    ? (calculatedValues.housingInput / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.housingInput}CAD
              </p>
              <button
                onClick={() => {
                  handleDeletationHousingSubmit();
                }}
                className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  -bold text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                  isTransitionActive
                    ? "transition-transform-active-button"
                    : "hidden"
                }`}
              >
                X
              </button>
            </div>
          </div>
        </CSSTransition>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
          <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
            Konaklama
          </h1>

          <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {housingValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressHousingBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>


      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start   sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={foodSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Gıda
          </h1>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-2 ">
            Aylık mutfak alışverişiniz ne kadarlık olacak?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => handleButtonClick("food", "foodId1")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food === "foodId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Tek Kişi <br></br> $350-$400CAD
          </button>
          <button
            onClick={() => handleButtonClick("food", "foodId2")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food === "foodId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            İki Kişi <br></br> $500-$600CAD
          </button>
          <button
            onClick={() => handleButtonClick("food", "foodId3")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food === "foodId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            İki Kişi+ <br></br> $500-$800CAD
          </button>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Dışarıdan yemek söyleyecek misiniz?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => handleButtonClick("food2", "food2Id1")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food2 === "food2Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 3-7 Kez <br></br> $60-$140CAD
          </button>
          <button
            onClick={() => handleButtonClick("food2", "food2Id2")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food2 === "food2Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 8-12 Kez <br></br> $160-$250CAD
          </button>
          <button
            onClick={() => handleButtonClick("food2", "food2Id3")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.food2 === "food2Id3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 15+ <br></br> $300-$500CAD
          </button>
        </div>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Gıda
        </h1>

        <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {foodValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressFoodBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>

      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start   sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={travelSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Ulaşım
          </h1>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-2 ">
            Araba mı, toplu taşıma mı kullanacaksınız?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => {
              handleButtonClick("travel", "travelId1");
              hideButtonsTravel();
              toggleTravelButtonsVisibility();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.travel === "travelId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Toplu Taşıma
          </button>
          <button
            onClick={() => {
              handleButtonClick("travel", "travelId2");
              hideButtonsTravel2();
              toggleTravelButtonsVisibility2();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.travel === "travelId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Araba
          </button>
        </div>

        <CSSTransition
          in={travelTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start" ref={travelButtonsRef}>
            <span className="mt-8 ">
              Ayda ne kadar kullanacaksınız?
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelButtonsVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4">
            <button
              onClick={() => handleButtonClick("travelAlt", "travelAltId1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt === "travelAltId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ayda 5-10 <br></br> $30-$60CAD
            </button>
            <button
              onClick={() => handleButtonClick("travelAlt", "travelAltId2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt === "travelAltId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ayda 15-20 <br></br> $90-$120CAD
            </button>
            <button
              onClick={() => handleButtonClick("travelAlt", "travelAltId3")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt === "travelAltId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Hergün <br></br> $156CAD(Monthly Pass, Toronto)
            </button>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelTextVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="mt-8 ">
              Yaşayacağınız şehrin toplu taşıma ücretlerini biliyorsanız aşağıya
              girebilirsiniz.
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelInputVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start">
            <input
              type="text"
              class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
              placeholder="Toplu Taşıma Ücreti $CAD"
              value={customTravelValue}
              onChange={handleTravelInputChange}
            />
            <button
              onClick={handleCustomTravelSubmit}
              className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
              >
              Ekle
            </button>

          <div class="flex justify-start mt-2">
              <p
                className={`flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                  isInputTravelTransitionActive
                    ? "transition-transform-active"
                    : "hidden"
                }`}
                style={{  Size: "12px" }} // Set the   size to be the same as the other elements
              >
                ${calculatedValues.travelInput >= 1000
                    ? (calculatedValues.travelInput / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.travelInput}CAD
              </p>
              <button
                onClick={() => {
                  handleDeletationTravelSubmit();
                }}
                className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  -bold text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                  isInputTravelTransitionActive
                    ? "transition-transform-active-button"
                    : "hidden"
                }`}
              >
                X
              </button>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelTextVisible2}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start" ref={travelButtonsRef2}>
            <span className="mt-8 ">
              Ayda ne kadar kullanacaksınız? (Fiyat ortalamasına sigorta, yakıt
              ve diğer masraflar eklenmiştir.)
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelButtonsVisible2}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4">
            <button
              onClick={() => handleButtonClick("travelAlt2", "travelAlt2Id1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt2 === "travelAlt2Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ayda 10-15 <br></br> $250-$400CAD
            </button>
            <button
              onClick={() => handleButtonClick("travelAlt2", "travelAlt2Id2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt2 === "travelAlt2Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Ayda 15-20 <br></br> $400-$500CAD
            </button>
            <button
              onClick={() => handleButtonClick("travelAlt2", "travelAlt2Id3")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
                selectedButtons.travelAlt2 === "travelAlt2Id3" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Hergün <br></br> $600-$900CAD
            </button>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelTextVisible2}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col justify-start">
            <span className="mt-8 ">
            Araba fiyatları çok değişiklik göstereceğinden aylık harcamalara dahil edilmemiştir. 
            Araba için harcamayı planladığınız miktarı aşağıya girebilirsiniz.
            </span>
          </div>
        </CSSTransition>

        <CSSTransition
          in={travelInputVisible2}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start" ref={incomeInputRef}>
            <input
              type="text"
              class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
              placeholder="Custom Amount $CAD"
              value={customTravel2Value}
              onChange={handleTravel2InputChange}
            />
            <button
              onClick={() => {
                handleCustomTravel2Submit();
              }}
              className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
              >
              Ekle
            </button>

            <div class="flex justify-start mt-2">
              <p
                className={`flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                  isInputTravel2TransitionActive
                    ? "transition-transform-active"
                    : "hidden"
                }`}
                style={{  Size: "12px" }} // Set the   size to be the same as the other elements
              >
                ${calculatedValues.travelInput2 >= 1000
                    ? (calculatedValues.travelInput2 / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.travelInput2}CAD
              </p>
              <button
                onClick={() => {
                  handleDeletationTravel2Submit();
                }}
                className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  -bold text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                  isInputTravel2TransitionActive
                    ? "transition-transform-active-button"
                    : "hidden"
                }`}
              >
                X
              </button>
            </div>
          </div>
        </CSSTransition>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Ulaşım
        </h1>

        <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {travelValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressTravelBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>

      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start   sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={walletSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Kişisel Harcamalar
          </h1>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-2 ">
            Düşük bütçeli aktivitelere(cafe, fast-food vs.) ne kadar harcama
            yapmayı planlıyorsunuz? ($30CAD ve altı)
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => handleButtonClick("personal", "personalId1")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personal === "personalId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 1-3 <br></br> $30-$80CAD
          </button>
          <button
            onClick={() => handleButtonClick("personal", "personalId2")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personal === "personalId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 4-7 <br></br> $120-$180CAD
          </button>
          <button
            onClick={() => handleButtonClick("personal", "personalId3")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personal === "personalId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 10-13 <br></br> $250-$300CAD
          </button>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Yüksek bütçeli aktivitelere(bar, restorant, konser vs.) ne kadar
            harcama yapmayı planlıyorsunuz? ($50CAD ve üstü)
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => handleButtonClick("personalAlt", "personalAltId1")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt === "personalAltId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 1-3 <br></br> $50-$180CAD
          </button>
          <button
            onClick={() => handleButtonClick("personalAlt", "personalAltId2")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt === "personalAltId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 4-7 <br></br> $200-$300CAD
          </button>
          <button
            onClick={() => handleButtonClick("personalAlt", "personalAltId3")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt === "personalAltId3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ayda 10-13 <br></br> $350-$600CAD
          </button>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Diğer kişisel harcamalarınız için ne kadar harcamayı planlıyorsunuz?
            (Giyim, hobi, teknoloji, ev eşyası vs.)
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => handleButtonClick("personalAlt2", "personalAlt2Id1")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt2 === "personalAlt2Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Az <br></br> $50-$150CAD
          </button>
          <button
            onClick={() => handleButtonClick("personalAlt2", "personalAlt2Id2")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt2 === "personalAlt2Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Ortalama <br></br> $200-$350CAD
          </button>
          <button
            onClick={() => handleButtonClick("personalAlt2", "personalAlt2Id3")}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] mb-2 ${
              selectedButtons.personalAlt2 === "personalAlt2Id3" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Çok <br></br> $350-$500CAD
          </button>
        </div>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className="sm:text-sm md:text-base lg:text-base xl:text-lg">
          Kişisel Harcamalar
        </h1>

        <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {personalValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressPersonalBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>

      <div className="  flex flex-col w-full p-8 mx-auto mt-[60px] mb-2  bg-white rounded-lg shadow-lg sm:w-3/5">
        <div className="flex-row items-center mb-4">
          <h1 className="flex items-center justify-start   sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-2xl">
            <img
              src={incomeSvg}
              alt=""
              className="w-4 h-4 mr-4 sm:w-4 sm:h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-10 xl:h-10"
            ></img>
            Gelirler
          </h1>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-4 ">
            Türkiye'den almaya devam edeceğiniz aylık ek bir geliriniz var mı?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => {
              handleButtonClick("income", "incomeId1");
              toggleIncomeInputVisibility();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.income === "incomeId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Evet
          </button>
          <button
            onClick={() => {
              handleButtonClick("income", "incomeId2");
              hideInputIncome();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.income === "incomeId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Hayır
          </button>
        </div>

        <CSSTransition
          in={incomeInputVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start" ref={incomeInputRef}>
            <input
              type="text"
              class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
              placeholder="Custom Amount $CAD"
              value={customIncomeValue}
              onChange={handleIncomeInputChange}
            />
            <button
              onClick={() => {
                handleCustomIncomeSubmit();
              }}
              className="px-4 font-bold py-2 mt-2  -bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
              >
              Ekle
            </button>

            <div class="flex justify-start mt-2">
              <p
                className={`flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                  isInputIncomeTransitionActive
                    ? "transition-transform-active"
                    : "hidden"
                }`}
                style={{  Size: "12px" }} // Set the   size to be the same as the other elements
              >
                ${calculatedValues.incomeAlt >= 1000
                    ? (calculatedValues.incomeAlt / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.incomeAlt}CAD
              </p>
              <button
                onClick={() => {
                  handleDeletationIncomeSubmit();
                }}
                className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                  isInputIncomeTransitionActive
                    ? "transition-transform-active-button"
                    : "hidden"
                }`}
              >
                X
              </button>
            </div>
          </div>
        </CSSTransition>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Kanada'da yasal olarak çalışma izniniz olacak mı?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => {
              handleButtonClick("incomeAlt", "incomeAltId1");
              toggleIncomeButtonVisibility();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.incomeAlt === "incomeAltId1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Evet
          </button>
          <button
            onClick={() => {
              handleButtonClick("incomeAlt", "incomeAltId2");
              hideButtonsIncome();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.incomeAlt === "incomeAltId2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Hayır
          </button>
        </div>

        <CSSTransition
          in={incomeButtonVisible}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4" ref={incomeButtonRef}>
            <button
              onClick={() => handleButtonClick("incomeAlt2", "incomeAlt2Id1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
                selectedButtons.incomeAlt2 === "incomeAlt2Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Part-Time <br></br> Haftada 20 Saat
            </button>
            <button
              onClick={() => handleButtonClick("incomeAlt2", "incomeAlt2Id2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
                selectedButtons.incomeAlt2 === "incomeAlt2Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Full-Time <br></br> Haftada 40 Saat
            </button>
          </div>
        </CSSTransition>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Kanada'ya geldiğinizde bir işte çalışmayı planlıyor musunuz?
          </span>
        </div>

        <div class="flex flex-wrap mt-4">
          <button
            onClick={() => {
              handleButtonClick("incomeAlt3", "incomeAlt3Id1");
              toggleIncomeButtonVisibility2();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.incomeAlt3 === "incomeAlt3Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Evet
          </button>
          <button
            onClick={() => {
              handleButtonClick("incomeAlt3", "incomeAlt3Id2");
              hideButtonsIncome2();
            }}
            className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
              selectedButtons.incomeAlt3 === "incomeAlt3Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
            } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
          >
            Hayır
          </button>
        </div>

        <CSSTransition
          in={incomeButtonVisible2}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="flex flex-wrap mt-4" ref={incomeButtonRef2}>
            <button
              onClick={() => handleButtonClick("incomeAlt4", "incomeAlt4Id1")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
                selectedButtons.incomeAlt4 === "incomeAlt4Id1" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Asgari Maaşlı Bir İş<br></br> Saatlik $15 - $20 CAD
            </button>
            <button
              onClick={() => handleButtonClick("incomeAlt4", "incomeAlt4Id2")}
              className={`flex-1 font-bold sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] ${
                selectedButtons.incomeAlt4 === "incomeAlt4Id2" ? "bg-red-700 text-white" : "bg-white text-red-700"
              } hover:bg-red-700 hover:text-white border-2 border-pathway-red  -bold py-4 px-4 rounded-md mr-2 transition-all duration-300`}
            >
              Asgari Maaş Üstünde Bir İş <br></br> Saatlik $20 - $30 CAD
            </button>
          </div>
        </CSSTransition>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Kanada'ya gelmeden önce biriktirmiş olduğunuz miktar ne kadar?
          </span>
        </div>

        <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start">
          <input
            type="text"
            class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
            placeholder="Birikim $CAD"
            value={customIncome2Value}
            onChange={handleIncome2InputChange}
          />
          <button
            onClick={() => {
              handleCustomIncome2Submit();
            }}
            className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
          >
            Ekle
          </button>

          <div class="flex justify-start mt-2">
            <p
              className={`px-4 flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                isInputIncome2TransitionActive
                  ? "transition-transform-active"
                  : "hidden"
              }`}
              style={{  Size: "12px" }}
            >
              ${calculatedValues.incomeAlt5 >= 1000
                    ? (calculatedValues.incomeAlt5 / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.incomeAlt5}CAD
            </p>
            <button
              onClick={() => {
                handleDeletationIncome2Submit();
              }}
              className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md flex-2 sm:flex-2 sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                isInputIncome2TransitionActive
                  ? "transition-transform-active-button"
                  : "hidden"
              }`}
            >
              X
            </button>
          </div>
        </div>

        <div class="flex justify-start">
          <span className="inline-block mt-8 ">
            Kanada'daki eğitiminizde sponsor olacak birisi var mı? Var ise
            miktarı aylık olarak giriniz.
          </span>
        </div>

        <div class="flex flex-col sm:flex-row xl:flex-nowrap sm:flex-wrap justify-start">
          <input
            type="text"
            class="border-2 border-pathway-red px-8 py-2 mb-2 mt-2 sm:mr-2 sm:mb-0 rounded-md"
            placeholder="Sponsor $CAD"
            value={customIncome3Value}
            onChange={handleIncome3InputChange}
          />
          <button
            onClick={() => {
              handleCustomIncome3Submit();
            }}
            className="px-4 py-2 mt-2 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md flex-2 sm:text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] hover:bg-red-700 hover:text-white border-pathway-red"
          >
            Ekle
          </button>

          <div class="flex justify-start mt-2">
            <p
              className={`px-4 flex items-center justify-center w-36 xl:ml-2 mr-[2px] sm:mr-[1px] text-white bg-red-700 border-2 border-pathway-red   rounded-l-md transition-transform ${
                isInputIncome3TransitionActive
                  ? "transition-transform-active"
                  : "hidden"
              }`}
                style={{  Size: "12px" }} // Set the   size to be the same as the other elements
              >
              ${calculatedValues.incomeAlt6 >= 1000
                    ? (calculatedValues.incomeAlt6 / 1000).toFixed(3).replace('.', ',') // Adding decimal point and comma
                    : calculatedValues.incomeAlt6}CAD
            </p>
            <button
              onClick={() => {
                handleDeletationIncome3Submit();
              }}
              className={`px-2 sm:px-4 py-2 mr-2 sm:mr-4  -bold text-white duration-300 bg-red-700 border-2 border-pathway-red rounded-r-md flex-2 sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-white hover:text-red-700 ${
                isInputIncome3TransitionActive
                  ? "transition-transform-active-button"
                  : "hidden"
              }`}
            >
              X
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Gelirler        
        </h1>

        <p className=" -bold sm:text-sm md:text-sm lg:text-sm xl:text-base">
            <animated.p className="">
              {incomeValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressIncomeBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
      </div>

      <div
        className="flex flex-col w-full p-8 mx-auto mt-2 mb-20 bg-white rounded-lg shadow-lg sm:w-3/5"
        style={{
          transition: "opacity 0.5s ease-in-out, top 0.5s ease-in-out",
          display: window.innerWidth < 640 || window.innerHeight < 670 ? "flex" : "none",
        }}
      >
        <h1 className=" -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Toplam Giderler        
        </h1>

        <p className=" -bold   sm:text-[7px] md:text-[9px] lg:text-[13px]">
            <animated.p className="">
              {totalValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar- ">
              <div
                className="progress-bar"
                ref={progressTotalBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>

        <h1 className="mt-2 -bold sm:text-sm md:text-base lg:text-base xl:text-lg">
          Toplam Gelirler        
        </h1>

        <p className=" -bold   sm:text-[7px] md:text-[9px] lg:text-[13px]">
            <animated.p className="">
              {incomeValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
          <div className="flex pt-1 mx-auto progress-bar- ">
            <div
              className="progress-bar"
              ref={progressIncomeTotalBarRef}
              style={{
                height: "10px",
                transition:
                  "width 1s ease-out, background-color 0.5s ease-out",
                borderRadius: "5px",
              }}
            ></div>
          </div>
        </p>

        <button
          onClick={() => navigateToResults()}
          className="px-4 py-2 mt-4 font-bold text-red-700 transition-colors duration-300 bg-white border-2 rounded-md -bold sm:text-xs md:text-sm lg:text-md xl:text-md hover:bg-red-700 hover:text-white border-pathway-red"
        >
          Bitir
        </button>
      </div>
      {shouldRenderSummary && <Summary />}

      <>
        <CopyrightYear />
      </>
    </div>
  );
};

export default Expenses;
