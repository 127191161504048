import React, {useState, useEffect} from "react";
import LoadingScreen from './LoadingScreen';
import './educationCanada.css'

import { GiFeather } from "react-icons/gi";


import Figure1 from './assets/pgwpkanada/1.png'
import Figure2 from './assets/pgwpkanada/2.png'
import Banner from './assets/kanadadayasam/banner2.png'

import Logo from './assets/basicLogo.png'


const PgwpCanada = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 300);
    });

    const handleClickPRE = () => {
        window.open('https://www.edupathwayscanada.com/on-degerlendirme', '_blank');
    };

    const handleClickNP = () => {
        window.open('https://www.northernpathways.ca', '_blank');
    };

    return (
        <div>
            <>{loading && <LoadingScreen />}
            </>

            <div className="flex justify-center min-h-screen">
                <div className="flex flex-col items-center xl:w-2/3 sm:w-full my-1 border border-gray-200">
                    <div className="flex flex-row items-center justify-start w-3/4 mt-16 h-fit" >
                        <img className="w-[20px] h-[20px]" src={Logo} alt="logo"></img>
                        
                        <p className="ml-6 text-sm text-gray-700">Yigit Pala</p>
                        <GiFeather className="ml-1 text-gray-700" />
                        
                        <p className="ml-6 text-sm text-gray-700">•</p>
                        <p className="ml-6 text-sm text-gray-700">15 Kas 2023</p>
                    </div>
                    <div className="flex flex-col w-3/4 mt-12">
                        <h1 className="text-5xl text-gray-700 border-l-2 border-gray-700">
                            <h1 className="ml-1">
                                Kanada'da Mezun Olduktan Sonra Çalışmak: PGWP                            
                            </h1>
                        </h1>

                        <p className="mt-8 text-justify text-gray-700">
                            Yurtdışında eğitim almak isteyenlerin öncelikli olarak merak ettikleri şeylerden biri de 
                            mezun olduktan sonra bulundukları ülkede çalışma fırsatı bulup bulmayacakları oluyor. 
                            Yurtdışında eğitim alırken hem sosyal hem profesyonel çevrenizi genişletecek farklı
                            fırsatlar bulabilirsiniz ancak asıl önemli olan, <b>eğitim aldığınız ülkenin mezun olduktan 
                            sonra bu fırsatları değerlendirebileceğiniz imkanalar sunabilmesi.</b>
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            İşte bu noktada Kanada’nın uluslararası öğrencilerin oldukça faydasına olan eğitim sonrası çalışma izni verdiği “Post Graduate Work Permit” yani “Mezuniyet Sonrası Çalışma İzni” devreye giriyor.
                        </p>

                        <div className="flex items-center justify-center w-full">
                            <img className="sm:w-[100%] xl:w-[80%] mt-8" src={Figure1} alt="kanada"></img>
                        </div>

                        <h1 className="mt-6 text-3xl text-red-700">Kanada'da PGWP Nedir?</h1>

                        <p className="text-justify text-gray-700">
                            PGWP, Kanada'da eğitim alan uluslararası öğrencilere, mezuniyet sonrasında ülkede çalışma hakkı tanıyan bir belgedir. Bu belge, öğrencilere Kanada'da çalışma deneyimi kazanma ve daha sonra kalıcı olarak bu ülkede yerleşme şansı elde etme imkânı sunar.
                            PGWP ile ilgili önemli bilgiler:
                        </p>

                        <h1 className="mt-6 text-xl text-gray-700">Mezuniyet Durumu </h1>
                        <p className="text-justify text-gray-700">
                            PGWP'yi alabilmek için öğrencinin bir Kanada bulunan bir devlet üniversitesinden veya kolejinden mezun olması gerekmektedir.
                        </p>

                        <h1 className="mt-6 text-xl text-gray-700">Programın Süresi</h1>
                        <p className="text-justify text-gray-700">
                            PGWP, öğrencinin aldığı eğitim programının süresine bağlı olarak verilir. Örneğin, bir yıl süren bir program için en fazla bir yıl, iki yıllık ver üzeri programlar için 3 yıllık süreyle PGWP alabilirsiniz.
                        </p>

                        <h1 className="mt-6 text-xl text-gray-700">Uygulama Zamanı</h1>
                        <p className="text-justify text-gray-700">
                            PGWP başvuruları, öğrencinin mezun olduktan sonraki 180 gün içinde yapılmalıdır.
                        </p>

                        <h1 className="mt-6 text-xl text-gray-700">Çalışma Süresi</h1>
                        <p className="text-justify text-gray-700">
                            PGWP, öğrencilere tam zamanlı çalışma hakkı sağlar. Bu süre, öğrencinin aldığı eğitim programının süresine bağlı olarak değişir.
                        </p>

                        <h1 className="mt-6 text-xl text-gray-700">Kalıcı İkamet Başvurusu</h1>
                        <p className="text-justify text-gray-700">
                            PGWP sahipleri, bu süre içinde Kanada'da çalışma deneyimi kazanabilir ve daha sonra Kanada'da kalıcı ikamet başvurusu yapabilir.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            PGWP, öğrencilere Kanada'da yaşamaya devam etme ve kariyerlerini geliştirme fırsatı sunarak, Kanada'da eğitim almanın avantajlarını arttırır. Bu belgeye başvurmadan önce güncel ve doğru bilgileri kontrol etmek ve IRCC onaylı bir göçmenlik danışmanı ile iletişime geçmek çok önemlidir.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            Bu noktada, PGWP hakkında daha fazla bilgi almak isterseniz Northern Pathways’in web sitesini ziyaret edebilirsiniz.
                        </p>

                        <div className="flex justify-center">
                            <img
                                className="mt-2 hover:cursor-pointer w-[300px] flex"
                                src={Banner}
                                alt="banner2"
                                onClick={handleClickNP}
                            /> 
                        </div>

                        <p className="mt-8 text-justify text-gray-700">
                            Özellikle niyeti yurtdışında yaşamak olan bireylerin değerlendirmesini önerdiğimiz PGWP’nin kişisel ve kariyer gelişimine oldukça katkısı vardır.
                        </p>


                        <h1 className="mt-6 text-3xl text-red-700">Örnek Seçenekler</h1>
                        <p className="text-justify text-gray-700">
                            "Mezuniyet Sonrası Çalışma İzni istiyorum, ne yapmam lazım?" sorusuna ufak bir örnek ile cevap verebiliriz. Diyelim ki bir senelik bir bölümde okumak istiyorsunuz ve en çok tercih edilen bölümlerden biri olan Project Management bölümü ile Toronto'ya geldiniz.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            Eğer dilerseniz mezuniyetinize yakın bir dönemde, ister farklı ister bağlantılı bir bölümde bir sene daha okuyabilir, iki eğitimi birleştirerek üç yıllık çalışma iznine başvurabilirsiniz.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            1+1 denilen bu sistemin faydası, öğrencinin Kanada'ya gelmeden önce göstermesi gereken maddi miktarı daha düşük tutma imkanı sağlaması ve farklı alanlarda özelleştirilmiş bir eğitim alma imkanı sunmasıdır.
                        </p>

                        <h1 className="mt-6 text-3xl text-red-700">PGWP Sahibi Olmanın Faydaları</h1>
                        <p className="text-justify text-gray-700">
                            <b>Kanada'da Yaşam Deneyimi:</b> PGWP sahipleri, Kanada kültürünü daha yakından tanıma ve yerel topluluklarıyla etkileşimde bulunma şansına sahiptir. Bu süreç, dil becerilerini geliştirmek ve Kanada'da yaşamaya alışmak için bir fırsat sunar.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            <b>Kariyer Gelişimi:</b> PGWP, öğrencilere Kanada'da kariyerlerini geliştirme ve uzun vadeli iş fırsatları arama olanağı sağlar. Çalışma deneyimi, özgeçmişlerini güçlendirebilir ve gelecekteki iş olanaklarını artırabilir.
                        </p>

                        <div className="flex items-center justify-center w-full">
                            <img className="sm:w-[100%] xl:w-[80%] mt-8" src={Figure2} alt="kanada-pgwp"></img>
                        </div>

                        <p className="mt-8 text-justify text-gray-700">
                            <b>Kanada'da Kalıcı İkamet İmkanı:</b> PGWP sahipleri, Kanada'da çalışma deneyimi kazanarak federal veya eyalet düzeyindeki kalıcı ikamet programlarına başvurabilirler. Çalışma deneyimi, kalıcı ikamet başvurularının değerlendirilmesinde olumlu bir etki yaratabilir.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            <b>Uluslararası Bağlantılar:</b> PGWP süresince öğrenciler, Kanada'daki iş dünyasıyla bağlantılar kurabilir ve endüstri spesifik ağlar oluşturabilirler. Bu, gelecekteki iş fırsatları için değerli olabilir.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            <b>Kanada'da Kalış Süresi:</b> PGWP, öğrencilere Kanada'da bir süre daha kalma ve yaşama şansı tanır. Bu süre zarfında ülkeyi daha iyi keşfetme ve yerleşme sürecine başlama fırsatı bulabilirler.
                        </p>

                        <hr class="mt-8 border-gray-800"></hr>
                        <p className="mt-4 text-center text-gray-700">
                            Tabii bu imkanlardan faydalanmak için öncelikle Kanada içinde devlet üniversite ya da kolejlerinde eğitim almak gerekiyor. Kanada’da eğitim hakkında bilgi ve kariyerinize yönelik okul önerileri almak isterseniz bizimle iletişime geçerek size özel hazırlanacak eğitim seçeneklerine ulaşabilirsiniz.
                        </p>

                        <div className="flex mb-8 items-center justify-center">
                            <button onClick={handleClickPRE} className="py-3 mt-8 text-sm text-white px-7 bg-pre-button rounded-2xl">
                                Ön Değerlendirme Formu
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PgwpCanada;