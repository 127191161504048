import React from 'react';

import { IoLocationOutline } from "react-icons/io5";
import { IoMailOutline } from "react-icons/io5";

import FooterImg from './assets/footer.png'

const Footer = () => {
    const currentYear = new Date().getFullYear();


    return (
        <footer>
            <div className='flex items-center justify-center h-full bg-footer'>
                <div className='flex flex-col items-center justify-center w-1/2'>
                    <img className='w-[300px]'src={FooterImg} alt='footer-image'></img>
                    <div className='flex flex-row max-[960px]:flex-col'>
                        <div className='flex flex-row justify-center mb-8 space-between'>
                            <IoMailOutline className='mr-2 text-white'/>
                            <p className='mb-2 text-white underline'>info@edupathways.ca</p>
                        </div>

                        <div className='flex flex-row justify-center '>
                            <IoLocationOutline className='mr-2 text-white'/>
                            <p className='text-white'>5200 Yonge Street, 2nd Floor
                                North York, ON, M2N 5P6</p>
                        </div>
                    </div>

                    <div className='flex justify-center mt-8 mb-8'>
                        <p className='text-white'>&copy; {currentYear} EduPathways Canada Education & Career Consulting Inc. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;