import React, {useState, useEffect} from "react";
import LoadingScreen from './LoadingScreen';

import { GiFeather } from "react-icons/gi";
import Logo from './assets/logo.png'

import Figure1 from './assets/kanadadayasamMasraflari/1.webp'
import Figure2 from './assets/kanadadayasamMasraflari/2.webp'
import Figure3 from './assets/kanadadayasamMasraflari/3.png'
import Figure4 from './assets/kanadadayasamMasraflari/4.png'
import Figure5 from './assets/kanadadayasamMasraflari/5.jpg'

import Banner from './assets/kanadadayasamMasraflari/banner.png'
import Banner2 from './assets/kanadadayasam/banner.png'


const LifeExpense = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 300);
    });

    const handleClick = () => {
        window.open('https://edupathwayskanadaegitim.com', '_blank');
      };

    const handleClickYT = () => {
        window.open('https://www.youtube.com/channel/UCIs2_aujQLd4vK-Uxg3E67Q', '_blank');
      };

    const handleClickPRE = () => {
        window.open('https://www.edupathwayscanada.com/on-degerlendirme', '_blank');
      };

    const handleRef = () => {
        window.open('https://livingcost.org/cost/canada/on/toronto', '_blank');
      };

    return (
        <div>
            <>{loading && <LoadingScreen />}
            </>

            <div className="flex justify-center min-h-screen">
                <div className="flex flex-col items-center my-1 border border-gray-200 xl:w-2/3 sm:w-full">
                    <div className="flex flex-row items-center justify-start w-3/4 mt-16 h-fit" >
                        <img className="w-[20px] h-[20px]" src={Logo} alt="logo"></img>
                        
                        <p className="ml-6 text-sm text-gray-700">Yigit Pala</p>
                        <GiFeather className="ml-1 text-gray-700" />
                        
                        <p className="ml-6 text-sm text-gray-700">•</p>
                        <p className="ml-6 text-sm text-gray-700">11 Eyl 2023</p>
                    </div>

                    <div className="flex flex-col w-3/4 mt-8">
                        <h1 className="text-3xl text-gray-700">Kanada'da Yaşam Masrafları</h1>

                        <p className="mt-2 text-justify text-gray-700"> 
                            <b>Kanada’da eğitim almak</b> ya da <b>Kanada’ya göç etmek</b> isteyen herkesin bir
                            aklındaki en önemli sorulardan biri de yaşam masraflarının nasıl olduğu oluyor.
                        </p>
                        <p className="mt-6 text-justify text-gray-700">
                            Kanada yaşam masrafları, eyaletlere ve şehirlere göre büyük ölçüde 
                            farklılık göstermekte. Özellikle Toronto ve Vancouver gibi büyük şehirlerin 
                            konut ücretleri dünya genelinde kıyasla oldukça yüksek olabiliyor.
                        </p>
                        <p className="mt-6 text-justify text-gray-700">
                            O yüzden Kanada’ya gelmeyi planlarken kalacağınız şehrin konut fiyatlarına, yaşam masraflarına ve 
                            ortalama maaşlarına mutlaka göz gezdirmenizi öneririz.
                            Yazımızın geri kalanında en çok tercih edilen şehir olduğu için <b>bu değerlendirmeleri Toronto 
                            için yapıyor olacağız.</b>
                        </p>

                        <img className="mt-8" src={Figure1} alt="kanadada"></img>

                        <h1 className="mt-8 text-3xl text-red-700">Toronto Yaşam Masrafları</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'nın en büyük şehirlerinden biri olan Toronto, çok kültürlü yapısı, iş fırsatları ve 
                            kaliteli yaşam tarzı ile dikkat çekiyor. Ancak, Toronto'da yaşamak isteyenler için yaşam 
                            masrafları hakkında doğru bilgilere sahip olmak önemlidir. İşte Toronto'da yaşamın getirdiği bazı 
                            temel masraflar
                        </p>

                        <img
                            className="mt-6 hover:cursor-pointer"
                            src={Banner}
                            alt="banner"
                            onClick={handleClick}
                        />    

                        <h1 className="mt-6 text-2xl text-red-700">Konut</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Toronto'da konut, yaşam masraflarının en büyük bölümünü oluşturabilir. Kiralık daire 
                            fiyatları konum ve dairenin büyüklüğüne göre değişiklik gösterir. <b>Ortalama olarak, 1+1 
                            dairelerin aylık kira bedeli 2.000-2.500 CAD arasında değişebilir.</b>
                        </p>

                        <img className="mt-8" src={Figure2} alt="kanadada-konut"></img>

                        <h1 className="mt-6 text-2xl text-red-700">Ulaşım</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Toronto'da toplu taşıma oldukça gelişmiştir. Otobüs, tramvay ve metro gibi seçeneklerle şehir 
                            içinde kolayca seyahat edebilirsiniz. <b>Aylık toplu taşıma kartı olan Presto Card (TTC) maliyeti 
                            yaklaşık 156 CAD'dir.</b> Eğer kendi arabanızı almayı planlıyorsanız durum biraz karışık bir hal alabiliyor.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Eğer Türkiyede'ki ehliyetinizi 3 yıl üzerinde ise, sigorta ücretleriniz ortalamanın daha altında olabiliyor. Bu 3 yıllık ehliyetinizi saydırmanız ehliyet sınavını ilk seferde geçebilmenize bağlı olduğunu da belirtmeden geçmeyelim.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Ortalama sigorta ücreti ise eyalet ve tecrübeye yönelik değişiklik göstermekle beraber ortalama olarak 150-300$CAD arasındadır.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Araba fiyatları hakkında ortalama bir fiyat bilgisine sahip olmanız açısından 2023 Model Toyota Corolla fiyatını aşağıda görebilirsiniz.
                        </p>

                        <img className="mt-8" src={Figure3} alt="kanadada-araba"></img>

                        <h1 className="mt-6 text-2xl text-red-700">Gıda</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Toronto'da süpermarketlerde alışveriş yapmak oldukça yaygındır. Costco, No Frills, Loblaws ve Farm Boy 
                            gibi süpermarketlerde genel olarak ihtiyacınız olan her şeyi rahatça bulabilirsiniz. Ayrıca özellikle 
                            Torontoda, Türk marketlerinin yaygın olduğunu da belirtmek gerekir. <b>Ortalama olarak, bir kişinin aylık 
                            gıda harcamaları 300-500 CAD arasında olabilir.</b>
                        </p>

                        <img
                            className="mt-6 hover:cursor-pointer"
                            src={Banner2}
                            alt="banner"
                            onClick={handleClickYT}
                        />  
                        
                        <h1 className="mt-6 text-2xl text-red-700">Eğlence ve Aktiviteler</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Toronto, kültürel etkinlikler, restoranlar, müzeler ve festivaller açısından zengin bir şehirdir. Eğlence ve aktiviteler için aylık bütçenizi 200-300 CAD arasında ayırmanız önerilir. Bu miktar kişinin tercihine bağlı olarak değişikliklik gösterebilmektedir.
                        </p>
                        
                        <img className="mt-8" src={Figure4} alt="kanadada-gece"></img>

                        <h1 className="mt-6 text-2xl text-red-700">Diğer Giderler</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Elektrik, su, internet gibi temel hizmetler ve kişisel harcamalar da yaşam masraflarınızı etkiler. Bazı konutlar yapılan anlaşmaya göre bu giderleri kiraya dahil edebilir.
                        </p>

                        <h1 className="mt-6 text-2xl text-red-700">Ortalama ve Asgari Maaş</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'da asgari maaş eyaletlere göre değişiklik göstermektedir. Toronto’da asgari maaş 2023 Kasım ayı itibari ile <b>16.55$CAD</b> olarak hesaplanacaktır. Ortalama maaş ise saatlik <b>27.67$CAD</b> olarak hesaplanmıştır. 
                        </p>
                        
                        <p className="mt-2 text-justify text-gray-700">
                            Toronto'da yaşam masrafları kişisel tercihlere ve yaşam tarzına bağlı olarak değişebilir. Şehirdeki iş fırsatları ve yaşam kalitesi, bu masrafları karşılayabileceğinizden emin olmanızı sağlayacaktır.
                        </p>

                        <img className="mt-8" src={Figure5} alt="kanadada-giderler"></img>
                        
                        <p className="mt-2 text-sm text-gray-700">Source:  
                            <a className="mt-1 ml-2 font-bold underline text-link" href="https://livingcost.org/cost/canada/on/toronto">Cost of Living in Toronto</a>
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            Toronto’daki fiyatlandırmalar hakkında daha fazla bilgi edinmek için aşağıdaki web sitesine göz gezdirmenizi öneririz.
                        </p>

                        <p className="mt-6 font-bold underline break-all text-link hover:cursor-pointer" onClick={handleRef}>
                            livingcost.org/cost/canada/on/toronto 
                        </p>

                        <hr class="mt-8 border-gray-800"></hr>

                        <p className="mt-4 text-center text-gray-700">
                            Kanada’da eğitim uluslararası öğrenciler için de ulaşılması zor olmayan bir hedef. Eğer siz de Kanada’da eğitim almayı ve hayata karşı bir adım önde başlamayı düşünüyorsanız web sitemizde bulunan ön değerlendirme formunu doldurarak bizimle iletişime geçebilir ve hayallerinize bir adım daha yaklaşabilirsiniz!
                        </p>

                        <div className="flex items-center justify-center mb-8">
                            <button onClick={handleClickPRE} className="py-5 mt-8 text-sm text-white px-7 bg-pre-button rounded-2xl">
                                Ön Değerlendirme Formu
                            </button>
                        </div>

                      
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LifeExpense;