import React from 'react';

class CopyrightYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
    };
  }

  render() {
    return (
      <footer>
        <p className="text-white sm:text-[8px] md:text-[9px] lg:text-[10px] xl:text-[12px]">
            &copy; {this.state.currentYear} EduPathways Canada Education & Career Consulting Inc. All rights reserved
        </p>
      </footer>
    );
  }
}

export default CopyrightYear;
