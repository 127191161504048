// AppContext.js
import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const initialCalculatedValues = {
    education: 0,
    educationInput: 0,
    housing: 0,
    housingAlt: 0,
    housingAlt2: 0,
    housingAlt3: 0,
    housingInput: 0,
    food: 0,
    food2: 0,
    travel: 0,
    travelAlt: 0,
    travelAlt2: 0,
    travelInput: 0,
    travelInput2: 0,
    personal: 0,
    personalAlt: 0,
    personalAlt2: 0,
    incomeAlt: 0,
    incomeAlt2: 0,
    incomeAlt4: 0,
    incomeAlt5: 0,
    incomeAlt6: 0,
    // Add more categories if needed
  };

  const [calculatedValues, setCalculatedValues] = useState(initialCalculatedValues);

  const updateCalculatedValue = (category, valueToAdd) => {
    setCalculatedValues((prevValues) => ({
      ...prevValues,  
      [category]: prevValues[category] + valueToAdd,
    }));
  };

  const resetCalculatedValues = () => {
    setCalculatedValues(initialCalculatedValues);
  };

  return (
    <AppContext.Provider value={{ calculatedValues, updateCalculatedValue, resetCalculatedValues }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
