import React, {useState, useEffect} from "react";
import LoadingScreen from './LoadingScreen';

import { GiFeather } from "react-icons/gi";
import Logo from './assets/logo.png'

import Figure1 from './assets/kanadadayasam/1.png'
import Figure2 from './assets/kanadadayasam/2.png'
import Figure3 from './assets/kanadadayasam/3.png'
import Figure4 from './assets/kanadadayasam/4.png'
import Figure5 from './assets/kanadadayasam/5.png'
import Figure6 from './assets/kanadadayasam/6.jpg'

import Banner from './assets/kanadadayasam/banner.png'
import Banner2 from './assets/kanadadayasam/banner2.png'

const Life = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 300);
    });

    const handleClick = () => {
        window.open('https://www.youtube.com/channel/UCIs2_aujQLd4vK-Uxg3E67Q', '_blank');
      };

    const handleClickNP = () => {
        window.open('https://www.northernpathways.ca', '_blank');
      };

    const handleClickPRE = () => {
        window.open('https://www.edupathwayscanada.com/on-degerlendirme', '_blank');
      };

    return (
        <div>
            <>{loading && <LoadingScreen />}
            </>

            <div className="flex justify-center min-h-screen">
                <div className="flex flex-col items-center my-1 border border-gray-200 xl:w-2/3 sm:w-full">
                    <div className="flex flex-row items-center justify-start w-3/4 mt-16 h-fit" >
                        <img className="w-[20px] h-[20px]" src={Logo} alt="logo"></img>
                        
                        <p className="ml-6 text-sm text-gray-700">Yigit Pala</p>
                        <GiFeather className="ml-1 text-gray-700" />
                        
                        <p className="ml-6 text-sm text-gray-700">•</p>
                        <p className="ml-6 text-sm text-gray-700">18 Kas 2022</p>
                    </div>

                    <div className="flex flex-col w-3/4 max-[960px]:w-full  mt-8">
                        <h1 className="text-3xl text-gray-700">Kanada’da Yaşam</h1>

                        <p className="text-justify text-gray-700"> Kanada, özellikle geçtiğimiz yıllardan itibaren, eğitim ve 
                            göçmenlik açısından dünyanın 1 numaralı ülkesi haline geldi. 
                            Kanada'da yaşamak sunduğu imkanlar sayesinde eğitim ve göçmenlik 
                            düşünen bireyler için beraberinde bazı kolaylıklar getirebiliyor.
                        </p>
                        <p className="mt-6 text-justify text-gray-700">
                            Sadece ülkemizden değil dünyanın geri kalan ülkelerinden de her 
                            yıl daha fazla kişinin geldiği Kanada’nın bu kadar çok tercih edilir 
                            olmasının nedenleri ile ilgili ufak bir araştırma yaptık. Bu yazımızda 
                            aşağıdaki konulardan bahsediyor olacağız;
                        </p>

                        <h1 className="mt-6 text-xl text-red-700 underline">Kanada'da Yaşam Nasıl?</h1>
                        
                        <ol className="font-bold text-gray-700 list-decimal">
                            <li className="ml-8">Kanada'da Maaşlar</li>
                        </ol>

                        <h1 className="mt-6 text-xl text-red-700 underline">Kanada'da Yaşamanın Avantajları</h1>

                        <ol className="font-bold text-gray-700 list-decimal">
                            <li className="ml-8">Çok kültürlü bir ülke olması</li>
                            <li className="ml-8">Yüksek yaşam kalitesi</li>
                            <li className="ml-8">Doğal güzellikler</li>
                            <li className="ml-8">Dost canlısı insanlar</li>
                            <li className="ml-8">Kaliteli eğitim olanakları</li>
                        </ol>

                        <h1 className="mt-6 text-xl text-red-700 underline">Kanada'da Nasıl Yaşarım?</h1>
                        
                        <ol className="font-bold text-gray-700 list-decimal">
                            <li className="ml-8">Kanada'ya Eğitim Yolu ile Gelmek</li>
                            <li className="ml-8">Kanada'ya Vatandaşlık Programları ile Gelmek</li>
                        </ol>

                        <h1 className="mt-6 text-xl text-red-700 underline">Kanada'ya Göç Etmek</h1>
                        
                        <ol className="font-bold text-gray-700 list-decimal">
                            <li className="ml-8">Kanada'da Yaşamak İçin Gereken Maddi Miktar</li>
                        </ol>

                        <hr class="mt-8 border-gray-800"></hr>

                        <img className="mt-8" src={Figure1} alt="kanadada-yasam"></img>

                        <h1 className="mt-6 text-3xl text-red-700">Kanada’da Yaşam Nasıl?</h1>

                        <p className="mt-2 text-justify text-gray-700"> <b>Kanada'da yaşam, ülkenin sosyal imkanları, eğitim olanakları ve 
                            kariyer seçenekleri gibi artıları ile yüksek bir yaşam kalitesi sunar. </b>
                            Bulunun işin niteliğine göre ortalamanın üstünde maaşlar kazanabilir, 
                            ek eğitimler ile kariyerinizde seviye atlayabilirsiniz. 
                            Ancak Kanada'daki yaşam kalitenizin bulunduğunuz şehre göre büyük oranda 
                            değişeceğini belirtmek gerekir.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Örneğin göçün çok olduğu Toronto, Vancouver, Montreal ya da 
                            Ontario'nun diğer büyük şehirlerini tercih ederseniz yüksek kiralar 
                            ve iş bulma konusunda fazla rekabet ile karşılaşabilirsiniz.
                        </p>

                        <h1 className="mt-6 text-2xl text-red-700">Kanada'da Maaşlar Nasıl?</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada, yüksek yaşam standardı, cömert sosyal imkanları ve çok kültürlü 
                            atmosferi ile göçmenler ve yurtdışında eğitim almak isteyenler için cazip 
                            bir destinasyondur. <b>Kanada'da eğitim almak, sadece kaliteli bir diploma 
                            edinmenize değil, aynı zamanda uluslararası bir iş deneyimi kazanmanıza 
                            ve global bir kariyere adım atmanıza da imkan tanır.</b>
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Kanada'daki maaşlar hakkında bilginiz olması açısından bazı önemli 
                            meslek sınıfları ve ortalama maaş bilgilerini aşağıda görebilirsiniz. 
                            Daha detaylı bilgi için <a className="font-bold underline text-link" href="https://www.statcan.gc.ca">www.statcan.gc.ca</a> adresini ziyaret edebilirsiniz.
                        </p>

                        <ol className="ml-4 list-decimal">
                            <li className="text-gray-700 ">
                                <h1 className="mt-6 text-xl font-bold text-gray-700">Bilgi ve İletişim Teknolojileri (BİT)</h1>
                                
                                <p className="mr-4 text-justify">
                                    Kanada, son yıllarda bilgi ve iletişim teknolojileri alanında hızlı bir 
                                    büyüme ve gelişme göstermektedir. Bu nedenle, BİT sektöründe çalışan 
                                    kalifiye elemanlara büyük bir talep mevcuttur. Yazılım geliştiriciler,
                                    veri analistleri, siber güvenlik uzmanları ve web tasarımcıları gibi BİT 
                                    alanındaki meslekler, Kanada'da en çok tercih edilen meslek sınıfları 
                                    arasında yer almaktadır.
                                </p>

                                <h1 className="mt-2 text-xl text-gray-700">Ortalama Maaşlar:</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Yazılım Geliştirici: $110,000 CAD/yıl
                                    </li>
                                    <li>
                                        Veri Analisti: $85,000 CAD/yıl
                                    </li>
                                    <li>
                                        Siber Güvenlik Uzmanı: $102,000 CAD/yıl
                                    </li>
                                    <li>
                                        Web Tasarımcısı: $65,000 CAD/yıl
                                    </li>
                                </ul>
                            </li>

                            <li className="text-gray-700 ">
                                <h1 className="mt-6 text-xl font-bold text-gray-700">Mühendislik</h1>

                                <p className="mr-4 text-justify">
                                    Kanada'da mühendislik, her zaman talep gören ve yüksek maaşlı 
                                    bir meslek grubudur. Makine mühendisleri, inşaat mühendisleri, 
                                    elektrik mühendisleri ve yazılım mühendisleri gibi mühendislik 
                                    alanlarındaki uzmanlar, Kanada'da iş bulmakta zorlanmazlar.
                                </p>

                                <h1 className="mt-2 text-xl text-gray-700">Ortalama Maaşlar:</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Makine Mühendisi: $108,000 CAD/yıl
                                    </li>
                                    <li>
                                        İnşaat Mühendisi: $105,000 CAD/yıl
                                    </li>
                                    <li>
                                        Elektrik Mühendisi: $102,000 CAD/yıl
                                    </li>
                                    <li>
                                        Yazılım Mühendisi: $110,000 CAD/yıl
                                    </li>
                                </ul>
                            </li>

                            <li className="text-gray-700 ">
                                <h1 className="mt-6 text-xl font-bold text-gray-700">Sağlık</h1>

                                <p className="mr-4 text-justify">
                                    Kanada'da sağlık sektörü de oldukça gelişmiş ve istikrarlı 
                                    bir sektördür. Doktorlar, hemşireler, eczacılar, fizyoterapistler 
                                    ve diş hekimleri gibi sağlık çalışanları, Kanada'da oldukça rağbet 
                                    görmektedir.
                                </p>

                                <h1 className="mt-2 text-xl text-gray-700">Ortalama Maaşlar:</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Doktor: $200,000 CAD/yıl
                                    </li>
                                    <li>
                                        Hemşire: $85,000 CAD/yıl
                                    </li>
                                    <li>
                                        Eczacı: $115,000 CAD/yıl
                                    </li>
                                    <li>
                                        Fizyoterapist: $80,000 CAD/yıl
                                    </li>
                                    <li>
                                        Diş Hekimi: $100,000 CAD/yıl
                                    </li>
                                </ul>
                            </li>

                            <li className="text-gray-700 ">
                                <h1 className="mt-6 text-xl font-bold text-gray-700">Eğitim</h1>

                                <p className="mr-4 text-justify">
                                    Kanada'da eğitim sistemi oldukça kalitelidir ve eğitimcilere 
                                    saygı duyulur. Öğretmenler, okul müdürleri ve eğitim danışmanları 
                                    gibi eğitimciler, Kanada'da iş bulmakta zorlanmazlar.
                                </p>

                                <h1 className="mt-2 text-xl text-gray-700">Ortalama Maaşlar:</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Öğretmen: $80,000 CAD/yıl
                                    </li>
                                    <li>
                                        Okul Müdürü: $105,000 CAD/yıl
                                    </li>
                                    <li>
                                        Eğitim Danışmanı: $75,000 CAD/yıl
                                    </li>
                                </ul>
                            </li>

                            <li className="text-gray-700 ">
                                <h1 className="mt-6 text-xl font-bold text-gray-700">İşletme ve Finans</h1>

                                <p className="mr-4 text-justify">
                                    Kanada'da işletme ve finans sektörü de oldukça gelişmiş ve istikrarlı bir sektördür. Muhasebeciler, finans analistleri, pazarlama uzmanları ve insan kaynakları uzmanları gibi işletme ve finans alanlarındaki uzmanlar, Kanada'da oldukça rağbet görmektedir.
                                </p>

                                <h1 className="mt-2 text-xl text-gray-700">Ortalama Maaşlar:</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Muhasebeci: $80,000 CAD/yıl
                                    </li>
                                    <li>
                                        Finans Analisti: $90,000 CAD/yıl
                                    </li>
                                    <li>
                                        Pazarlama Uzmanı: $75,000 CAD/yıl
                                    </li>
                                    <li>
                                        İnsan Kaynakları Uzmanı: $95,000 CAD/yıl
                                    </li>
                                </ul>

                                <h1 className="mt-6 text-xl text-gray-700">Kanada'da Kariyer Yapmak İçin İpuçları</h1>

                                <ul className="ml-12 text-gray-700 list-disc">
                                    <li>
                                        Kanada'da iş bulmak için, öncelikle bu ülkede geçerli bir çalışma iznine sahip olmanız gerekir.
                                    </li>
                                    <li>
                                        Kanada'da eğitim almak, iş bulma şansınızı önemli ölçüde artırır.
                                    </li>
                                    <li>
                                        İngilizce ve/veya Fransızca dillerini iyi bilmeniz, Kanada'da iş bulmanızı kolaylaştıracaktır. 
                                    </li>
                                    <li>
                                        Kanada'daki iş kültürü hakkında bilgi edinmeniz ve buna uygun davranmanız önemlidir.
                                    </li>
                                    <li>
                                        İş başvurularında profesyonel bir özgeçmiş ve ön yazı hazırlamanız gerekir.
                                    </li>
                                    <li>
                                        Network oluşturmak ve iş bağlantıları kurmak, Kanada'da iş bulma şansınızı artıracaktır.
                                    </li>
                                </ul>
                            </li>
                        </ol>

                        <h1 className="mt-6 text-3xl text-red-700">Kanada'da Yaşamanın Avantajları</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'da yaşamanın oldukça fazla avantajı vardır. Sadece eğitim kalitesi 
                            açısından değil, iş bulma olanakları ve kültürel seçenekler gibi faktörler 
                            de Kanada'daki yaşamınızı olumlu yönde etkileyecektir. "Kanada'da yaşamanın 
                            ne gibi avantajları var?" sorusunu soracak olursanız;
                        </p>

                        <ul className="mt-6 ml-4 list-decimal">
                            <li className="ml-2 text-xl text-red-700">
                                <h1 className="text-2xl text-red-700">Çok kültürlü bir ülke olması</h1>

                                <p className="text-base text-justify text-gray-700">
                                    <b>Özellikle 2021-2022 yılları arasında, bir önceki senenin rakamlarını 
                                    ikiye katlayarak 492.984 yeni göçmeni ülkeye kabul etti. </b> 
                                    Önceki yıllardan beri gelen kişi sayısını da göz önünde bulunduracak 
                                    olursak hemen hemen her milletten birini bulunduran Kanada, 
                                    bu konuda birinci sırada geliyor.
                                </p>

                                <p className="mt-6 text-base text-justify text-gray-700">
                                    Farklı kültürlere karşı uyguladığı bu politika, <b>Kanada’nın yeni 
                                    gelen göçmenleri hoşgörü ile karşılaması ve onlara ihtiyacı olan 
                                    her türlü yardımı sunması</b> ile ülkeyi daha çok tercih edilir kılıyor.
                                </p>

                                <img className="mt-8" src={Figure2} alt="kanadada-yasam2"></img>
                            </li>

                            <li className="mt-6 ml-2 text-xl text-red-700">
                                <h1 className="text-2xl font-thin text-red-700">Yüksek yaşam kalitesi</h1>

                                <p className="text-base text-justify text-gray-700">
                                    Kanada'da yaşam, <b>hemen hemen herkesin hobisi ve tercihlerine uygun 
                                    bir alternatif sunmayı başarabiliyor.</b> Hobileriniz her ne olursa olsun 
                                    onlarla ilgili mutlaka bir grup ya da topluluk bulabilirsiniz. 
                                    Konserler, tiyatrolar ve gösteriler açısından da oldukça dolu bir
                                    programı olan Kanada’da yapacak bir şeyler bulmak çok kolay bir hale 
                                    geliyor.
                                </p>

                                <p className="mt-6 text-base text-justify text-gray-700">
                                    <b>Ayrıca iş imkanlarının bolluğu insanların kişisel ihtiyaçlarını 
                                    karşılanabilir bir hale getiriyor.</b> 2023 yılının sonuna kadar 
                                    uluslararası öğrencilerin tam zamanlı çalışabilir hale gelmesi, 
                                    Kanada’nın iş konusunda daha fazla insana ihtiyaç duyduğunu da ortaya 
                                    seriyor.
                                </p>

                                <img className="mt-8" src={Figure3} alt="kanadada-yasam3"></img>

                                <p className="mt-6 text-base text-justify text-gray-700">
                                    Kanada, yaşam kalitesi açısından dünya liderleri arasında yer alır. 
                                    <b>Kanada iş olanakları</b>, sağlık hizmetleri, sosyal hizmetler ve çevresel 
                                    faktörler gibi birçok alanda yüksek standartlara sahiptir. <b>Kanada'da 
                                    çalışmak</b>, öğrencilere farklı sektörlerde iş deneyimi kazandırırken 
                                    aynı zamanda öğrencilerin maddi olarak bağımsızlığını elde etmelerine 
                                    yardımcı olur.
                                </p>

                                <p className="mt-6 text-base text-justify text-gray-700">
                                    Ülkedeki çalışma koşulları da oldukça adil ve öğrencilerin iş hakkı 
                                    da önemsenir. Kanada, sunduğu eğitim fırsatları, yüksek yaşam 
                                    standartları ve sıcakkanlı insanlarıyla dünya genelinde öğrencilerin 
                                    en çok tercih ettiği yerlerden biridir.
                                </p>

                                <img
                                    className="mt-6 hover:cursor-pointer"
                                    src={Banner}
                                    alt="banner"
                                    onClick={handleClick}
                                />                            
                            </li>

                            <li className="mt-6 ml-2 text-xl text-red-700">
                                <h1 className="text-2xl font-thin text-red-700">Doğal güzellikler</h1>

                                <p className="text-base text-justify text-gray-700">
                                    Kanada çoğu açıdan dünyanın en güzel ülkelerinden biri olabilir. 
                                    Ülkenin doğal güzellikleri, saymakla bitmeyen gölleri ve bunların 
                                    ulaşılabilirliği ülkenin en ilgi çekici özelliklerinden birkaçı. 
                                    Şehir merkezlerinde bile farkında olmadan harika bir parka denk 
                                    gelebilir ve kendinizi kaybedebilirsiniz. Doğal güzelliklerinin 
                                    yanı sıra mimari açılardan da göze hitap eden tasarımlarıyla şehirleri 
                                    her sezon başka güzel.
                                </p>

                                <img className="mt-8" src={Figure4} alt="kanadada-yasam4"></img>                         
                            </li>

                            <li className="mt-6 ml-2 text-xl text-red-700">
                                <h1 className="text-2xl font-thin text-red-700">Dost canlısı insanlar</h1>

                                <p className="text-base text-justify text-gray-700">
                                    Kanada’nın en ünlü özelliklerinden biri de insanların ne kadar dost 
                                    canlısı ve nazik olduklarıdır. Üstelik bu özellik sadece Kanadalılar 
                                    için değil, Kanada’ya gelmiş herkes için geçerli. Bu; ülkede yaşayan 
                                    herkes tarafından kabul edilen ve özenle uyulan bir kural gibi 
                                    görülmekte.
                                </p>
                            </li>

                            <li className="mt-6 ml-2 text-xl text-red-700">
                                <h1 className="text-2xl font-thin text-red-700">Kaliteli eğitim olanakları</h1>

                                <p className="text-base text-justify text-gray-700">
                                    Eğitim açısından Kanada dünyanın en önde gelen ülkelerinden biridir. 
                                    Yüzlerce farklı üniversite ve kolejleri sayesinde hemen hemen herkese 
                                    hitap edebilecek binlerce bölümü kolaylıkla bulabilirsiniz. 
                                    Eğitimlerini tamamlayan ve Kanada’da kalmak isteyen öğrencilerin 
                                    mezun olduktan sonra iş bulmaları da oldukça kolay.
                                </p>

                                <img className="mt-8" src={Figure5} alt="kanadada-yasam5"></img>                         
                            </li>
                        </ul>

                        <h1 className="mt-6 text-xl text-red-700 underline">Kanada'da Nasıl Yaşarım?</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'da yaşamak için izleyebileceğiniz bir kaç farklı yol var. Bunları araştırırken en çok dikkat etmeniz gereken şey hangi seçeneğin sizin için daha uygun olduğu olmalı. En çok tercih edilen yollardan bazıları, eğitim ya da vatandaşlık programları aracılığı ile gelmek.
                        </p>

                        <h1 className="mt-6 text-2xl text-red-700">Kanada'ya Eğitim Yolu ile Gelmek</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada, <b>dünyanın en iyi üniversitelerinden bazılarına</b> ev sahipliği yapan bir ülke olarak ün kazanmıştır. 
                            <b>Kanada'daki üniversiteler</b>, kaliteli eğitim programları ve nitelikli öğretim kadroları ile öğrencilere harika bir eğitim fırsatı sunar.                         
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            <b>Kanada'da üniversiteye gitmek</b> isteyen öğrencilerin birçok seçeneği vardır ve 
                            ülkedeki okulların birçoğu yüksek öğrenim alanında uzmanlaşmıştır. <b>Kanada'nın 
                            üniversite eğitimi</b>, öğrencilerin gerçek hayatta uygulayabilecekleri pratik 
                            beceriler ve deneyimlerle doludur. Ayrıca, Kanada'da okul hayatı, kültürel ve 
                            sosyal deneyimlerle dolu bir yaşam sunar.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Ayrıca seçeceğiniz programın türüne göre co-op opsiyonları ile mezuniyetiniz 
                            sonrasında bölümünüze uygun bir işte çalışmaya başlayabilirsiniz. Ancak bu 
                            bölümlerde okulun size iş garantisi sözü yoktur, sadece iş bulmanıza yardımcı 
                            olabilecek kaynak ve olanakları sunarlar.
                        </p>

                        <h1 className="mt-6 text-2xl text-red-700">Kanada'ya Vatandaşlık Programları ile Gelmek</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'da her eyaletin ihtiyaç duyduğu iş gücünü karşılamak için oluşturduğu 
                            bazı eyalet adaylık programları vardır. Bu kategori üzerinden vatandaşlık 
                            hakkına sahip olmak isteyen göçmenlerin karşılamaları gereken belli 
                            kriterler vardır. Bu konuda daha detaylı bilgi almak için göçmenlik 
                            danışmanı çözüm ortağımız <a className="font-bold text-gray-700" href="https://www.northernpathways.ca"> Northern Pathways</a>'in web sitesini ziyaret 
                            etmenizi öneririz.
                        </p>

                        <div className="flex justify-center">
                            <img
                                className="mt-2 hover:cursor-pointer w-[240px] flex"
                                src={Banner2}
                                alt="banner2"
                                onClick={handleClickNP}
                            /> 
                        </div>

                        <h1 className="mt-6 text-3xl text-red-700">Kanada'ya Göç Etmek</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Eğer hedefiniz Kanada'ya göç etmek ise düzgün bir araştırma yapmanız oldukça önemli. Çünkü asıl zorluk Kanada'ya gelmek değil, burada kalıcı olabilmekte. Kanada'ya gelme hazırlıklarınız sırasında kalıcılığınızı etkileyen önemli faktörlerin bilincinde olmanız gerekiyor.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Eğer hedefiniz Kanada'ya göç etmek ise düzgün bir araştırma yapmanız oldukça önemli. Çünkü asıl zorluk Kanada'ya gelmek değil, burada kalıcı olabilmekte. Kanada'ya gelme hazırlıklarınız sırasında kalıcılığınızı etkileyen önemli faktörlerin bilincinde olmanız gerekiyor.
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Örneğin; Türkiye'de 3 yıla kadar gösterebileceğiniz bir iş tecrübeniz, kalıcı oturum başvurunuz sırasında size fazladan puan getirebilir. Dil seviyeniz, öğrenim durumunuz, yaşınız gibi faktörlerde alacağınız puanı büyük oranda değiştirebiliyor.
                        </p>

                        <img className="mt-8" src={Figure6} alt="kanadada-yasam6"></img>                         
                 
                        <h1 className="mt-6 text-2xl text-red-700">Kanada'ya Eğitim Yolu ile Gelmek</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Öncelikle hangi yolla geleceğinizi netleştirmeniz gerekiyor. 
                            Eğer hedefiniz eğitim yolu ile gelmekse, <b>Kanada'da ihtiyacınız 
                            olacak olan ayrıca vize başvurusu sırasında göstermeniz gereken miktar ortalama 
                            20.000 CAD civarındadır.</b> Bu gereklilik ana başvuru sahibi için geçerlidir.                        
                        </p>

                        <h1 className="mt-6 text-2xl text-red-700">Kanada Öğrenci Vizesi Nasıl Alınır?</h1>

                        <p className="mt-2 text-justify text-gray-700">
                            Kanada'da öğrenci vizesi almak için öncelikle alacağınız eğitimi ve okulu 
                            seçmeniz gerekiyor.
                            <b>Okul seçimi konusunda tecrübelerimizden faydalanmak isterseniz </b> 
                            <a className="font-bold underline text-link" href="https://www.edupathwayscanada.com/on-degerlendirme">ön değerlendirme</a>                  
                            <b> formunu doldurarak bizimle iletişime geçebilirsiniz.</b>  
                        </p>

                        <p className="mt-6 text-justify text-gray-700">
                            Kanada Şubat 2024 itibariyle uluslararası öğrencilerin statülerini ilgilendiren 
                            çok önemli değişiklikler yaptı. <b>Seçeceğiniz eğitimin türüne karar vermeden 
                            önce bu değişiklikleri okumanız oldukça önemli;</b>                  
                        </p>

                        <ul className="mt-6 ml-4 text-gray-700 list-decimal">
                            <li>
                                <p className="text-justify ">
                                    2024 yılı için belirlenen sınır yaklaşık 360.000 öğrenci vizesinin onaylanması şeklinde. Bu, 2023'e göre %35'lik bir azalma anlamına geliyor.
                                </p>
                                <p className="mt-4 text-justify">
                                    Her bir eyalete, nüfusuna göre kapasite verilecek. Kapasitenin uluslararası öğrenci sayılarında sürdürülemez bir artış yaşayan eyaletlerde belirgin bir şekilde düşürüleceği ifade ediliyor. Bakan Miller verdiği örnekle, Ontario eyaletine kabul edilen uluslararası öğrencilerin sayısının yüzde elli oranında azalacağını belirtti. Eyaletler kendilerine verilen kapasiteyi okulları arasında bölüştürmekten sorumlu olacaklar.
                                </p>
                            </li>

                            <li className="mt-6">
                                <p className="text-justify">
                                    1 Eylül 2024 tarihinden itibaren, özel kariyer kolejlerinin devlet kolejleriyle ortak yürüttüğü programlara başlayan öğrenciler mezuniyet sonrası çalışma izni başvurusu yapamayacaklar. (Bu tür programlarda öğrencilere, özel bir kolejin binasında okulun anlaşmalı olduğu bir devlet kolejinin müfredatı sunuluyor.)
                                </p>

                                <p className="mt-4 text-justify">
                                    Yapılan duyuruda ayrıca yüksek lisans mezunlarının, programın süresine bakılmaksızın üç yıllık çalışma izni başvurusunda bulunabilecekleri paylaşıldı. Bu uygulamanın yakında başlayacağı ifade edildi.
                                </p>
                            </li>

                            <li className="mt-6">
                                <p className="text-justify">
                                    Bundan sonra açık çalışma izni başvurusu yapma hakkı yalnızca yüksek lisans ve doktora programlarında okuyan uluslararası öğrencilerin eşlerine verilecek. Bu değişiklikle birlikte, diğer öğrenim seviyelerindeki uluslararası öğrencilerin eşleri, lisans ve kolej programları da dahil olmak üzere, açık çalışma izni başvurusu yapamayacaklar.
                                </p>
                            </li>
                        </ul>

                        <hr class="mt-8 border-gray-800"></hr>

                        <p className="mt-4 text-center text-gray-700">
                            Kanada’da eğitim uluslararası öğrenciler için de ulaşılması zor olmayan bir hedef. Eğer siz de Kanada’da eğitim almayı ve hayata karşı bir adım önde başlamayı düşünüyorsanız web sitemizde bulunan ön değerlendirme formunu doldurarak bizimle iletişime geçebilir ve hayallerinize bir adım daha yaklaşabilirsiniz!
                        </p>

                        <div className="flex items-center justify-center">
                            <button onClick={handleClickPRE} className="py-5 mt-8 text-sm text-white px-7 bg-pre-button rounded-2xl">
                                Ön Değerlendirme Formu
                            </button>
                        </div>


                        <ul className="mt-6 mb-8 list-disc">
                            <p className="text-sm font-bold text-gray-700">
                                Kaynaklar;
                            </p>
                            <li className="ml-12 text-gray-700">
                                <a className="text-xs font-bold underline break-all text-link" href="https://www.northernpathways.ca/post/kanadanin-uluslararasi-ogrenci-politikasinda-onemli-degisiklikler">
                                    https://www.northernpathways.ca/post/kanadanin-uluslararasi-ogrenci-politikasinda-onemli-degisiklikler
                                </a>                            
                            </li>

                            <li className="ml-12 text-gray-700">
                                <a className="text-xs font-bold underline break-all text-link" href="https://www.jobbank.gc.ca">https://www.jobbank.gc.ca</a>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Life;