// Results.js
import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import LoadingScreen from "./LoadingScreen";
import CopyrightYear from "./Copyright";
import '../src/App.css'

import { useAppContext } from "./AppContext";
import { useSpring, animated } from "react-spring";

import ReactSpeedometer from "react-d3-speedometer";

import ReactiveButton from "reactive-button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faBomb,
  faCircleNotch,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const Results = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const { calculatedValues, resetCalculatedValues, updateCalculatedValue } =
    useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);

    // Clean up function to be called when the component is unmounted
    return () => {
      // Reset calculated values when navigating away from the Results page
      resetCalculatedValues();
    };
  }, [resetCalculatedValues]);

  const edValue = (parseFloat(calculatedValues.education) + parseFloat(calculatedValues.educationInput));

  const { educationValue } = useSpring({
    from: { educationValue: 0 },
    to: {
      educationValue: 
      (parseFloat(calculatedValues.education) + parseFloat(calculatedValues.educationInput)),
    },
    delay: 1250,
    reset: false,
  });


  const hValue = parseInt(    
    calculatedValues.housing +
    calculatedValues.housingAlt +
    calculatedValues.housingAlt2 +
    calculatedValues.housingAlt3 +
    calculatedValues.housingInput);

  const { housingValue } = useSpring({
    from: { housingValue: 0 },
    to: {
      housingValue: parseInt(    
        calculatedValues.housing +
        calculatedValues.housingAlt +
        calculatedValues.housingAlt2 +
        calculatedValues.housingAlt3 +
        calculatedValues.housingInput),
    },
    delay: 1250,
    reset: false,
  });

  const { housingValueYear } = useSpring({
    from: { housingValueYear: 0 },
    to: {
      housingValueYear: parseInt(    
        (calculatedValues.housing +
        calculatedValues.housingAlt +
        calculatedValues.housingAlt2 +
        calculatedValues.housingAlt3 +
        calculatedValues.housingInput) * 12),
    },
    delay: 1250,
    reset: false,
  });

  const fValue =  parseFloat(calculatedValues.food) + parseFloat(calculatedValues.food2);

  const { foodValue } = useSpring({
    from: { foodValue: 0 },
    to: {
      foodValue: 
      (parseFloat(calculatedValues.food) + parseFloat(calculatedValues.food2)),
    },
    delay: 1250,
    reset: false,
  });

  const { foodValueYear } = useSpring({
    from: { foodValueYear: 0 },
    to: {
      foodValueYear: 
      (parseFloat(calculatedValues.food) + parseFloat(calculatedValues.food2)) * 12,
    },
    delay: 1250,
    reset: false,
  });

  const tValue =
    calculatedValues.travel +
    calculatedValues.travelAlt +
    calculatedValues.travelAlt2 +
    calculatedValues.travelInput + 
    calculatedValues.travelInput2;

  const { travelValue } = useSpring({
    from: { travelValue: 0 },
    to: {
      travelValue: 
       parseInt(calculatedValues.travel) + parseInt(calculatedValues.travelAlt) + parseInt(calculatedValues.travelAlt2) + parseInt(calculatedValues.travelInput) + parseInt(calculatedValues.travelInput2),
    },
    delay: 1250,
    reset: false,
  });

  const { travelValueYear } = useSpring({
    from: { travelValueYear: 0 },
    to: {
      travelValueYear: parseInt(tValue * 12, 10),
    },
    delay: 1250,
    reset: false,
  });

  const pValue = parseInt(calculatedValues.personal) + parseInt(calculatedValues.personalAlt) + parseInt(calculatedValues.personalAlt2);

  const { personalValue } = useSpring({
    from: { personalValue: 0 },
    to: {
      personalValue: 
      parseInt(calculatedValues.personal) + parseInt(calculatedValues.personalAlt) + parseInt(calculatedValues.personalAlt2),
    },
    delay: 1250,
    reset: false,
  });

  const { personalValueYear } = useSpring({
    from: { personalValueYear: 0 },
    to: {
      personalValueYear: 
      (parseInt(calculatedValues.personal) + parseInt(calculatedValues.personalAlt) + parseInt(calculatedValues.personalAlt2)) * 12,
    },
    delay: 1250,
    reset: false,
  });

  const hour = parseInt(calculatedValues.incomeAlt2);
  const price = parseInt(calculatedValues.incomeAlt4);

  const { incomeValue } = useSpring({
    from: { incomeValue: 0 },
    to: {
      incomeValue:
        parseInt(
          calculatedValues.incomeAlt2 * calculatedValues.incomeAlt4 * 4
        ) * 12,
    },
    delay: 1250,
    reset: false,
  });

  const { incomeValueYear } = useSpring({
    from: { incomeValueYear: 0 },
    to: {
      incomeValueYear: 
      (parseInt(calculatedValues.incomeAlt) + (parseInt(calculatedValues.incomeAlt2) * parseInt(calculatedValues.incomeAlt4) * 4) + parseInt(calculatedValues.incomeAlt6)) * 12 + parseInt(calculatedValues.incomeAlt5),
    },
    delay: 1250,
    reset: false,
  });

  const { incomeValueMonth } = useSpring({
    from: { incomeValueMonth: 0 },
    to: {
      incomeValueMonth: 
      parseInt(calculatedValues.incomeAlt) + (parseInt(calculatedValues.incomeAlt2) * parseInt(calculatedValues.incomeAlt4) * 4) + parseInt(calculatedValues.incomeAlt5) + parseInt(calculatedValues.incomeAlt6),
    },
    delay: 1250,
    reset: false,
  });

  const sValue = parseInt(calculatedValues.incomeAlt6);

  const { sponsorValue } = useSpring({
    from: { sponsorValue: 0 },
    to: { sponsorValue: parseInt(calculatedValues.incomeAlt6) },
    delay: 1250,
    reset: false,
  });

  const { sponsorValueYear } = useSpring({
    from: { sponsorValueYear: 0 },
    to: { sponsorValueYear: parseInt(calculatedValues.incomeAlt6 * 12) },
    delay: 1250,
    reset: false,
  });

  const bankValue = parseInt(calculatedValues.incomeAlt5);

  const { savedValue } = useSpring({
    from: { savedValue: 0 },
    to: { savedValue: parseInt(calculatedValues.incomeAlt5) },
    delay: 1250,
    reset: false,
  });

  const passValue = parseInt(calculatedValues.incomeAlt);

  const { passiveValue } = useSpring({
    from: { passiveValue: 0 },
    to: { passiveValue: parseInt(calculatedValues.incomeAlt) },
    delay: 1250,
    reset: false,
  });

  const { passiveValueYear } = useSpring({
    from: { passiveValueYear: 0 },
    to: { passiveValueYear: parseInt(calculatedValues.incomeAlt * 12) },
    delay: 1250,
    reset: false,
  });

  const totalSum =
    calculatedValues.housing +
    calculatedValues.housingAlt +
    calculatedValues.housingAlt2 +
    calculatedValues.housingAlt3 +
    calculatedValues.housingInput +
    calculatedValues.food +
    calculatedValues.food2 +
    calculatedValues.travel +
    calculatedValues.travelAlt +
    calculatedValues.travelAlt2 +
    calculatedValues.travelInput +
    calculatedValues.personal +
    calculatedValues.personalAlt +
    calculatedValues.personalAlt2;

  const { totalValueMonth } = useSpring({
    from: { totalValueMonth: 0 },
    to: { totalValueMonth: parseInt(totalSum) },
    delay: 1250,
    reset: false,
  });

  const { totalValueYear } = useSpring({
    from: { totalValueYear: 0 },
    to: {
      totalValueYear:
        parseInt(totalSum * 12) +
        calculatedValues.education +
        calculatedValues.educationInput,
    },
    delay: 1250,
    reset: false,
  });

  const incomeValueYearDial =
    (calculatedValues.incomeAlt +
      calculatedValues.incomeAlt2 * calculatedValues.incomeAlt4 * 4 +
      calculatedValues.incomeAlt6) *
      12 +
    calculatedValues.incomeAlt5;

  const totalValueYearDial =
    totalSum * 12 +
    calculatedValues.education +
    calculatedValues.educationInput;

  const [rotation, setRotation] = useState(0);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const calculateRotation = () => {
      const rawRotation = (incomeValueYearDial / totalValueYearDial) * 50;
      const newRotation = Math.min(rawRotation, 100); // Limit the rotation to a maximum of 100

      setRotation(newRotation);
      setShouldRender(true);
    };

    const delay = 1500; // Adjust the delay time in milliseconds as needed

    const timeoutId = setTimeout(() => {
      calculateRotation();
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [incomeValueYearDial, totalValueYearDial]);

  const [containerWidth, setContainerWidth] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1423) {
        setContainerWidth("24%");
      } else {
        setContainerWidth("25%");
      }
    };

    // Initial call to set the initial width
    handleResize();

    // Add window resize event listener
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  //--------------------------------------------------
  const [educationInput, setEducationInput] = useState(edValue);
  const [stateEducation, setStateEducation] = useState("idle");

  const handleEducationInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setEducationInput(inputValue);
    }
  };

  const handleEducationClick = () => {
    setStateEducation("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateEducation("success");
    }, 1250);

    calculatedValues.education = parseFloat(educationInput);
  };

  //--------------------------------------------------

  const [housingInput, setHousingInput] = useState(hValue);
  const [stateHousing, setStateHousing] = useState("idle");

  const handleHousingInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setHousingInput(inputValue);
    }  
  };

  const handleHousingClick = () => {
    setStateHousing("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateHousing("success");
    }, 1250);

    calculatedValues.housing = 0;
    calculatedValues.housingAlt = 0;
    calculatedValues.housingAlt2 = 0;
    calculatedValues.housingAlt3 = 0;
    calculatedValues.housingInput = parseFloat(housingInput);
  };

  //--------------------------------------------------

  const [foodInput, setFoodInput] = useState(fValue);
  const [stateFood, setStateFood] = useState("idle");

  const handleFoodInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setFoodInput(inputValue);
    }
  };

  const handleFoodClick = () => {
    setStateFood("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateFood("success");
    }, 1250);

    calculatedValues.food = 0;
    calculatedValues.food2 = parseFloat(foodInput);
  };

  //--------------------------------------------------

  const [travelInput, setTravelInput] = useState(tValue);
  const [stateTravel, setStateTravel] = useState("idle");

  const handleTravelInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setTravelInput(inputValue);
    }
  };

  const handleTravelClick = () => {
    setStateTravel("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateTravel("success");
    }, 1250);

    calculatedValues.travel = 0;
    calculatedValues.travelAlt = 0;
    calculatedValues.travelAlt2 = 0;
    calculatedValues.travelInput2 = 0;
    calculatedValues.travelInput = parseFloat(travelInput);
  };

  //--------------------------------------------------

  const [personalInput, setPersonalInput] = useState(pValue);
  const [statePersonal, setStatePersonal] = useState("idle");

  const handlePersonalInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setPersonalInput(inputValue);
    }
  };

  const handlePersonalClick = () => {
    setStatePersonal("loading");

    // send an HTTP request
    setTimeout(() => {
      setStatePersonal("success");
    }, 1250);

    calculatedValues.personal = 0;
    calculatedValues.personalAlt = 0;
    calculatedValues.personalAlt2 = parseFloat(personalInput);
  };

  //--------------------------------------------------

  const [hourInput, setHourInput] = useState(hour);
  const [stateHour, setStateHour] = useState("idle");

  const handleHourInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setHourInput(inputValue);
    }
  };

  const handleHourClick = () => {
    setStateHour("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateHour("success");
    }, 1250);

    calculatedValues.incomeAlt2 = hourInput;
  };

  //--------------------------------------------------

  const [priceInput, setPriceInput] = useState(price);
  const [statePrice, setStatePrice] = useState("idle");

  const handlePriceInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setPriceInput(inputValue);
    }
  };

  const handlePriceClick = () => {
    setStatePrice("loading");

    // send an HTTP request
    setTimeout(() => {
      setStatePrice("success");
    }, 1250);

    calculatedValues.incomeAlt4 = priceInput;
  };

  //--------------------------------------------------

  const [passiveInput, setPassiveInput] = useState(passValue);
  const [statePassive, setStatePassive] = useState("idle");

  const handlePassiveInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setPassiveInput(inputValue);
    }
  };

  const handlePassiveClick = () => {
    setStatePassive("loading");

    // send an HTTP request
    setTimeout(() => {
      setStatePassive("success");
    }, 1250);

    calculatedValues.incomeAlt = passiveInput;
  };

  //--------------------------------------------------

  const [bankInput, setBankInput] = useState(bankValue);
  const [stateBank, setStateBank] = useState("idle");

  const handleBankInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setBankInput(inputValue);
    }
  };

  const handleBankClick = () => {
    setStateBank("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateBank("success");
    }, 1250);

    calculatedValues.incomeAlt5 = bankInput;
  };

  //--------------------------------------------------

  const [sponsorInput, setSponsorInput] = useState(sValue);
  const [stateSponsor, setStateSponsor] = useState("idle");

  const handleSponsorInputChange = (e) => {
    const inputValue = e.target.value;

    if (/^\d*\.?\d*$/.test(inputValue)) {
      setSponsorInput(inputValue);
    }
  };

  const handleSponsorClick = () => {
    setStateSponsor("loading");

    // send an HTTP request
    setTimeout(() => {
      setStateSponsor("success");
    }, 1250);

    calculatedValues.incomeAlt6 = sponsorInput;
  };

  function segmentValueFormatter(value) {
    if (value < 50) {
      return `🙁` 
      
    }
    if (value < 100) {
      return `Gider = Gelir`
    }
      return `🙂`
    }

    useEffect(() => {
      const handleScroll = () => {
        const threshold = 100;
        setIsOpen(window.scrollY > threshold);
      };
  
      window.addEventListener("scroll", handleScroll);
  
      handleScroll();
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const handlePreNavigation = () => {
      window.open('https://www.edupathwayscanada.com/on-degerlendirme', '_blank');
    };

  return (
    <div>
      <>
        {loading && <LoadingScreen />}
      </>
      <div className="flex flex-col items-center justify-center">
        <h1 className="mt-4 sm:text-2xl md:text-3xl lg:text-4xl -raleway">
          Finansal Durum Özetiniz
        </h1>
      </div>

      <div className="flex flex-col items-center justify-center"> 
        <div
          className="flex flex-row flex-wrap w-full p-8 mt-4 bg-white rounded-lg shadow-lg shadow-gray-500"
          style={{
            display: window.innerWidth < 1280 ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
            <ReactSpeedometer
              width={275}
              height={160}
              needleHeightRatio={0.6}
              forceRender={true}
              needleTransitionDuration={2000}
              needleTransition="easeElastic"
              needleColor="#B91D1B"
              segments={250}
              maxValue={100}
              value={rotation}
              currentValueText=" "
              maxSegmentLabels={3}
              segmentValueFormatter={segmentValueFormatter}
            />

            <div
              className="flex flex-col relative items-center justify-center text-[14px]"
              style={{
                display: window.innerWidth < 1280 ? "flex" : "none",
                maxWidth: "100%",
              }}>
              <p className="text-center">
                Bu hesap makinesi, Kanada’daki gelir/gider dengesi hakkında bilgi sahibi olmanız amacıyla tasarlanmıştır, kesinlik içermemektedir. 
                Başvurularınızı ya da Kanada’daki yeni yaşantınızı planlarken <b>buradaki verileri baz alarak plan yapmamanız önerilir. </b>
              </p>
              <br></br>
              <p className="text-center">
                Diyagram gelir/gider dengesini görsel olarak göstermektedir. Buradaki görsel hesaplamada gelirlerinize, biriktirmiş olduğunuz bankadaki miktar dahildir.
              </p>
            </div>

            <p className="mt-4 text-center text-black">Kanada’daki eğitim hayatınız hakkında detaylı bilgi için; </p>
            <button 
                onClick={handlePreNavigation}
                className="flex items-center justify-center px-6 py-2 mx-auto mt-4 text-white border-2 rounded-lg bg-button-green border-button-green hover:bg-white hover:text-button-green">
                  ÖN DEĞERLENDİRME
            </button>
          
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="flex flex-col p-8 pl-4 mt-8 xl:ml-8 bg-white rounded-lg shadow-lg shadow-gray-400 sm:w-full md:w-full lg:w-full xl:w-[33%]">
            <h1 className="mt-4 sm:text-lg md:text-xl lg:text-2xl -raleway">
              Giderler
            </h1>
            <hr className="my-5 border-b-2 border-gray-500 " />

            <div className="flex flex-row mx-auto pb-[16px]">
              <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                Eğitim (Yıllık)
              </h1>
              <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                <animated.p className="">
                  {educationValue.interpolate((val) => {
                    return val >= 1000
                      ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                      : `$${Math.floor(val)}CAD`;
                  })}
                </animated.p>
              </p>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Konaklama
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {housingValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Konaklama
                </h1>
                <p className="italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {housingValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Gıda
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {foodValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>           
                </p>
              </div>
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Gıda
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {foodValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Ulaşım
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {travelValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Ulaşım
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {travelValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Kişisel Harcamalar
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {personalValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Kişisel Harcamalar
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {personalValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col p-8 pl-4 mt-8 xl:ml-8 mb-4 bg-red-700 text-white rounded-lg shadow-lg shadow-black xl:w-[45%]"
            style={{
              display: window.innerWidth < 1280 ? "flex" : "none",
            }}
          >
            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Toplam Giderler
                </h1>
                <p className="italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplama Okul Ücreti Dahil Edilmiştir
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Toplam Giderler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {totalValueMonth.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>


              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplam Giderler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {totalValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>

          

          <div className="flex flex-col mt-8 xl:ml-8 bg-white items-center p-8 rounded-lg shadow-lg shadow-gray-400 sm:w-full md:w-full lg:w-full xl:w-[25%]">
            <div
              className="relative flex"
              style={{
                display: window.innerWidth < 1280 ? "none" : "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactSpeedometer
                width={window.innerWidth / 5.5}
                height={175}
                needleHeightRatio={0.6}
                forceRender={true}
                needleTransitionDuration={2000}
                needleTransition="easeElastic"
                needleColor="#B91D1B"
                segments={250}
                maxValue={100}
                value={rotation}
                currentValueText=" "
                maxSegmentLabels={3}
                segmentValueFormatter={segmentValueFormatter}
              />
            </div>

            <div
              className="relative flex flex-col items-center justify-center"
              style={{
                display: window.innerWidth < 1280 ? "none" : "flex",
                maxWidth: "100%",
              }}>
              <p className="text-center">
                Bu hesap makinesi, Kanada’daki gelir/gider dengesi hakkında bilgi sahibi olmanız amacıyla tasarlanmıştır, kesinlik içermemektedir. 
                Başvurularınızı ya da Kanada’daki yeni yaşantınızı planlarken <b>buradaki verileri baz alarak plan yapmamanız önerilir.</b>
              </p>
              <br></br>
              <p className="text-center">
                Diyagram gelir/gider dengesini görsel olarak göstermektedir. Buradaki görsel hesaplamada gelirlerinize, biriktirmiş olduğunuz bankadaki miktar dahildir.
              </p>
            </div>
          </div>

          <div className="flex flex-col p-8 pl-4 mt-8 xl:ml-[30px] xl:mr-8 bg-white rounded-lg shadow-lg shadow-gray-400 sm:w-full md:w-full lg:w-full xl:w-[33%]">
            <div className="flex flex-col mx-auto ">
              <h1 className="mt-4 sm:text-lg md:text-xl lg:text-2xl -raleway">
                Gelir ve Birikimler
              </h1>

              <hr className="my-5 border-b-2 border-gray-500 " />

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Toplam Yıllık Gelir
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {incomeValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yasal Çalışma İzniniz
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {calculatedValues.incomeAlt2} Saat
                  </animated.p>
                  
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Saatlik Ücret
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    Ortalama ${calculatedValues.incomeAlt4} CAD
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Gelir
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {calculatedValues.incomeAlt2} Saat x{" "}
                    ${calculatedValues.incomeAlt4} CAD x 4 ={" "}
                    ${calculatedValues.incomeAlt2 *
                      calculatedValues.incomeAlt4 *
                      4}{" "}
                     CAD
                  </animated.p>
                </p>
              </div>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Pasif Gelir
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {passiveValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Pasif Gelir
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {passiveValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>

            <div className="flex flex-row mx-auto pb-[16px]">
              <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                Biriktirilen Miktar
              </h1>
              <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                <animated.p className="">
                    {savedValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
              </p>
            </div>

            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Yıllık Sponsor Geliri
                </h1>
                <p className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  <animated.p className="">
                    {sponsorValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Sponsor Gelir
                </h1>
                <p className="italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {sponsorValue.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col p-8 pl-4 mt-8 xl:ml-8 mb-4 bg-red-700 text-white rounded-lg shadow-lg shadow-black xl:w-[45%]"
            style={{
              display: window.innerWidth < 1280 ? "flex" : "none",
            }}
          >
            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Toplam Gelirler
                </h1>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Toplam Gelirler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {incomeValueMonth.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplam Gelirler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {incomeValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col p-8 pl-4 mt-8 ml-8 mb-4 h-[150px] bg-red-700 text-white rounded-lg shadow-lg shadow-black sm:w-full md:w-full lg:w-full xl:w-[33%]"
            style={{
              display: window.innerWidth < 1280 ? "none" : "flex",
            }}
          >
            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Toplam Giderler
                </h1>
                <p className="italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplama Okul Ücreti Dahil Edilmiştir
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Toplam Giderler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {totalValueMonth.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplam Giderler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {totalValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>
          
          <div
            className="flex flex-col p-8 mt-8 mb-4 h-[150px] bg-white text-red-700 rounded-lg shadow-lg shadow-black sm:w-full md:w-full lg:w-full xl:w-[25%]"
            style={{
              display: window.innerWidth < 1280 ? "none" : "flex",
            }}
          >
            <p className="mb-[12px] text-black">Kanada’daki eğitim ve kariyer hayatınız hakkında detaylı bilgi için; <br></br></p>
            <button 
                onClick={handlePreNavigation}
                className="flex items-center justify-center px-2 py-2 mx-auto mb-4 text-white border-2 rounded-lg bg-button-green border-button-green hover:bg-white hover:text-button-green">
                  ÖN DEĞERLENDİRME
            </button>          
          </div>

          <div
            className="flex flex-col p-8 pl-4 mt-8 mr-8 mb-4 h-[150px] bg-red-700 text-white rounded-lg shadow-lg shadow-black sm:w-full md:w-full lg:w-full xl:w-[33%]"
            style={{
              display: window.innerWidth < 1280 ? "none" : "flex",
            }}
          >
            <div className="flex flex-col mx-auto ">
              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="    sm:text-[13px] md:text-[13px] lg:text-[15px]">
                  Toplam Gelirler
                </h1>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Aylık Toplam Gelirler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {incomeValueMonth.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>

              <div className="flex flex-row mx-auto pb-[16px]">
                <h1 className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  Yıllık Toplam Gelirler
                </h1>
                <p className="ml-4 italic     sm:text-[13px] md:text-[13px] lg:text-[13px]">
                  <animated.p className="">
                    {incomeValueYear.interpolate((val) => {
                      return val >= 1000
                        ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                        : `$${Math.floor(val)}CAD`;
                    })}
                  </animated.p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full xl:ml-2 sm:ml-0">
        <div className="flex flex-col p-8 mt-8 mb-8 bg-white rounded-lg shadow-lg shadow-gray-400 sm:mr-[22px]">
          <div className="flex flex-row">
            <h1 className="ml-[40px]     sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl  -raleway">
              Yukarıdaki Tabloda Değişiklik Yapmak İstiyorsanız Aşağıdan Yeni Veri Girişi Yapabilirsiniz
            </h1>
          </div>

          <div
            className={`absolute ml-0 cursor-pointer transform transition-transform ${
              isOpen ? "rotate-90" : "rotate-0"
            }`}
          >
            <h1 className=" sm:text-2xl md:text-3xl lg:text-3xl xl:text-3xl -raleway">
              &#8594;
            </h1>
          </div>

          {isOpen && (
            <div className="mt-2 mr-2 wrapped-div">
              <div className="flex flex-wrap xl:flex-row sm:flex-col">
                <div className="relative flex flex-col p-8 mt-4 bg-white rounded-lg shadow-lg shadow-gray-400 sm:mr-[22px] xl:w-[48%] sm:w-full">
                  <h1 className=" sm:text-lg md:text-xl lg:text-2xl -raleway">
                    Giderler
                  </h1>

                  <hr className="my-5 border-b-2 border-gray-500 " />

                  <div className="table w-full">
                    <div className="table-header-group">
                      <div className="table-row">
                        <div className="table-cell text-left"></div>
                        <div className="table-cell text-left"></div>
                        <div className="table-cell text-left"></div>
                      </div>
                    </div>
                    <div className="table-row-group">
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Eğitim (Yıllık)
                          </h1>
                          <h1 className="mt-2 ml-4 italic     sm:text-[7px] md:text-[10px] lg:text-[13px]">
                            Eğitim (Aylık)
                          </h1> 
                        </div>
                        <div className="table-cell">
                          <input  
                            type="text"
                            className="h-8 pl-2 border-2 rounded-md w-[64px] border-pathway-red"
                            value={educationInput}
                            onChange={handleEducationInputChange}
                          />
                          <p className="mt-1 ml-4 italic     sm:text-[7px] md:text-[10px] lg:text-[13px]">
                            ${((parseFloat(calculatedValues.education) + parseFloat(calculatedValues.educationInput))/12).toFixed(2)}CAD/Aylık
                          </p>
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateEducation}
                            onClick={() => {
                              handleEducationClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Konaklama
                          </h1>
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="flex h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={housingInput}
                            onChange={handleHousingInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateHousing}
                            onClick={() => {
                              handleHousingClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Gıda
                          </h1>
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={foodInput}
                            onChange={handleFoodInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateFood}
                            onClick={() => {
                              handleFoodClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell ...">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Ulaşım
                          </h1>
                        </div>
                        <div className="table-cell ...">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={travelInput}
                            onChange={handleTravelInputChange}
                          />
                        </div>
                        <div className="table-cell ...">
                          <ReactiveButton
                            buttonState={stateTravel}
                            onClick={() => {
                              handleTravelClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell ...">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Kişisel Harcamalar
                          </h1>
                        </div>
                        <div className="table-cell ...">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={personalInput}
                            onChange={handlePersonalInputChange}
                          />
                        </div>
                        <div className="table-cell ...">
                          <ReactiveButton
                            buttonState={statePersonal}
                            onClick={() => {
                              handlePersonalClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative flex flex-col p-8 mt-4 bg-white rounded-lg shadow-lg shadow-gray-400 sm:mr-[22px] xl:w-[48%] sm:w-full">
                  <h1 className=" sm:text-lg md:text-xl lg:text-2xl -raleway">
                    Gelir ve Birikimler
                  </h1>

                  <hr className="my-5 border-b-2 border-gray-500 " />

                  <div className="table w-full">
                    <div className="table-header-group">
                      <div className="table-row">
                        <div className="table-cell text-left"></div>
                        <div className="table-cell text-left"></div>
                        <div className="table-cell text-left"></div>
                      </div>
                    </div>
                    <div className="table-row-group">
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Yasal Çalışma İzniniz
                          </h1>
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={hourInput}
                            onChange={handleHourInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateHour}
                            onClick={() => {
                              handleHourClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="mt-2     sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Saatlik Ücret
                          </h1>
                          <h1 className="mt-2 ml-4 italic     sm:text-[7px] md:text-[10px] lg:text-[13px]">
                            Aylık Gelir
                          </h1> 
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={priceInput}
                            onChange={handlePriceInputChange}
                          />
                          <p className="mt-1 ml-4 italic     sm:text-[7px] md:text-[10px] lg:text-[13px]">
                            ${parseFloat(calculatedValues.incomeAlt2 * calculatedValues.incomeAlt4 * 4).toFixed(2)}CAD
                          </p>
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={statePrice}
                            onClick={() => {
                              handlePriceClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Pasif Gelir
                          </h1> 
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={passiveInput}
                            onChange={handlePassiveInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={statePassive}
                            onClick={() => {
                              handlePassiveClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Biriktirilen Miktar
                          </h1>
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 border-2 rounded-md w-[64px] border-pathway-red"

                            value={bankInput}
                            onChange={handleBankInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateBank}
                            onClick={() => {
                              handleBankClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                      <div className="table-row">
                        <div className="table-cell">
                          <h1 className="    mr-4 sm:text-[10px] md:text-[11px] lg:text-[15px]">
                            Aylık Sponsor Geliri
                          </h1>
                        </div>
                        <div className="table-cell">
                          <input
                            type="text"
                            className="h-8 pl-2 mt-2 mr-1 border-2 rounded-md w-[64px] border-pathway-red"

                            value={sponsorInput}
                            onChange={handleSponsorInputChange}
                          />
                        </div>
                        <div className="table-cell">
                          <ReactiveButton
                            buttonState={stateSponsor}
                            onClick={() => {
                              handleSponsorClick();
                            }}
                            color={"red"}
                            idleText={<FontAwesomeIcon icon={faPlus} />}
                            loadingText={
                              <>
                                <FontAwesomeIcon icon={faCircleNotch} spin />
                              </>
                            }
                            successText={
                              <>
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </>
                            }
                            errorText={
                              <>
                                <FontAwesomeIcon icon={faBomb} />
                              </>
                            }
                            type={"button"}
                            className={"class1 class2"}
                            style={{
                              background: "#B91D1B",
                              width: "50px",
                              height: "25px",
                              borderRadius: "5px",
                            }}
                            outline={false}
                            shadow={false}
                            rounded={false}
                            size={"small"}
                            block={false}
                            messageDuration={1250}
                            disabled={false}
                            buttonRef={null}
                            width={null}
                            height={null}
                            animation={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <>
        <CopyrightYear />
      </> 
    </div>
  );
};

export default Results;
