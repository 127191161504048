// Header.js
import React, { useState, useEffect } from 'react';
import logo from './assets/eduPathways_small.png';
import '../src/App.css'
import './Header.css'
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';


const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    const navigate = useNavigate();

    const handleHomeNavigation = () => {
        window.open('https://www.edupathwayscanada.com',  '_blank');
    };

    const handlePreNavigation = () => {
      window.open('https://www.edupathwayscanada.com/on-degerlendirme',  '_blank');
    };

    const handleRoute = (buttonId) => {
      navigate(`/${buttonId}`);
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
      setIsOpen(!isOpen);
    };

    const handleRouteMobile = () => {
      setIsOpen(!isOpen);
    };

    const navItems = [
      { id: 'kanadadayasam', text: 'Kanada’da Yaşam' },
      { id: 'kanada-da-yasam-masraflari', text: 'Kanada’da Yaşam Masrafları' },
      { id: 'kanada-da-egitim', text: 'Kanada’da Eğitim' },
      { id: 'kanada-da-mezun-olduktan-sonra-calismak-pgwp', text: 'Mezuniyet Sonrası Kariyer' },
      { id: '', text: 'Ana Sayfa' },
    ];

  return (
    <header id='header' className="flex flex-col items-center pb-4 bg-white border-b-4 border-red-700">
      <div className="text-center">
        <img onClick={handleHomeNavigation} src={logo} alt="Logo" className="inline-block w-1/3 h-1/3 hover:cursor-pointer" />
      </div>

      <nav className="w-full py-1 bg-white">
        {windowWidth >= 1024 && (
          <div className="px-4 mx-auto max-w-7xl sm:hidden md:hidden lg:block xl:block 2xl:block">
            <div className="flex justify-between">
              <div className="flex space-x-4">
                <button 
                  onClick={handlePreNavigation}
                  className="flex-col px-3 py-2 text-xs font-[600] bg-button-green rounded-xl text-white font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:bg-green-800 animated-border">
                  Ücretsiz Görüşme
                </button>
                <button 
                  id='kanadadayasam'
                  onClick={() => handleRoute('kanadadayasam')}
                  className="px-3 py-2 text-xs font-[600] text-red-700 font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:text-red-900">
                  Kanada’da Yaşam
                </button>
                <button 
                  id='kanadadayasammasraflari'
                  onClick={() => handleRoute('kanada-da-yasam-masraflari')}
                  className="px-3 py-2 text-xs font-[600] text-red-700 font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:text-red-900">
                  Kanada’da Yaşam Masrafları
                </button>
                <button 
                  id='kanadadaegitim'
                  onClick={() => handleRoute('kanada-da-egitim')}
                  className="px-3 py-2 text-xs font-[600] text-red-700 font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:text-red-900">
                  Kanada’da Eğitim
                </button>
                <button 
                  id='mezuniyetsonrasikariyer'
                  onClick={() => handleRoute('kanada-da-mezun-olduktan-sonra-calismak-pgwp')}
                  className="px-3 py-2 text-xs font-[600] text-red-700 font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:text-red-900">
                  Mezuniyet Sonrası Kariyer
                </button>
                <button 
                  id='/'
                  onClick={() => handleRoute('')}
                  className="px-3 py-2 text-xs font-[600] text-red-700 font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:text-red-900">
                  Ana Sayfa
                </button>
              </div>
            </div>
          </div>
        )}

        {windowWidth < 1024 && (
          <div className="px-4 mx-auto max-w-7xl">
            <div onClick={toggleNavbar} className='max-[960px]:flex md:flex lg:hidden xl:hidden 2xl:hidden justify-end items-center'>
                {isOpen ? <AiOutlineClose className="text-red-700 hover:cursor-pointer" size={20} /> : <AiOutlineMenu className="text-red-700 hover:cursor-pointer" size={20} />}
            </div>

            <ul
              className={
                isOpen
                  ? 'fixed sm:block md:block left-0 top-0 w-[60%] h-full border-r-2 border-r-red-700 bg-white ease-in-out duration-500'
                  : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
              }
            >

              {/* Mobile Navigation Items */}
              {navItems.map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    handleRoute(item.id);
                    handleRouteMobile(); // Call handleRouteMobile function
                  }}
                  className='p-4 border-b-2 hover:bg-red-700 text-red-700 font-bold hover:text-white duration-300  cursor-pointer border-red-700'
                >
                  {item.text}
                </li>
              ))}

              <li className='flex items-center justify-center mt-4'>
                <button 
                  onClick={handlePreNavigation}
                  className="flex-col px-24 py-6 text-xs font-[600] bg-button-green rounded-xl text-white font sm:text-[12px] md:text-[12px] lg:text-[13px] xl:text-[13px] hover:bg-green-800 animated-border">
                    Ücretsiz Görüşme
                </button>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;