import React, {useState, useEffect} from "react";
import LoadingScreen from './LoadingScreen';
import './educationCanada.css'

import Figure1 from './assets/kanadadaegitim/1.png'
import Figure2 from './assets/kanadadaegitim/2.webp'
import Figure3 from './assets/kanadadaegitim/3.webp'
import Figure4 from './assets/kanadadaegitim/4.jpg'
import Figure5 from './assets/kanadadaegitim/5.webp'
import Figure6 from './assets/kanadadaegitim/6.webp'
import Figure7 from './assets/kanadadaegitim/7.jpg'
import Figure8 from './assets/kanadadaegitim/8.webp'
import Figure9 from './assets/kanadadaegitim/9.jpg'
import Figure10 from './assets/kanadadaegitim/10.webp'
import Figure11 from './assets/kanadadaegitim/11.jpeg'
import Figure12 from './assets/kanadadaegitim/12.jpg'
import Figure13 from './assets/kanadadaegitim/13.jpg'
import Figure14 from './assets/kanadadaegitim/14.jpeg'
import Figure15 from './assets/kanadadaegitim/15.png'
import Figure16 from './assets/kanadadaegitim/16.jpg'
import Figure17 from './assets/kanadadaegitim/17.jpg'
import Figure18 from './assets/kanadadaegitim/18.png'
import Figure19 from './assets/kanadadaegitim/19.webp'
import Figure20 from './assets/kanadadaegitim/20.jpg'
import Figure21 from './assets/kanadadaegitim/21.jpg'
import Figure22 from './assets/kanadadaegitim/22.jpg'
import Figure23 from './assets/kanadadaegitim/23.png'
import Figure24 from './assets/kanadadaegitim/24.png'
import Logo from './assets/basicLogo.png'


const EducationCanada = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => setLoading(false), 300);
    });

    const handleClickPRE = () => {
        window.open('https://www.edupathwayscanada.com/on-degerlendirme', '_blank');
      };

    return (
        <div>
            <>{loading && <LoadingScreen />}
            </>

            <div className="flex justify-center min-h-screen">
                <div className="flex flex-col items-center xl:w-2/3 sm:w-full my-1 border border-gray-200">
                    <div className="flex flex-col w-3/4 mt-12">
                        <h1 className="text-5xl text-gray-700 border-l-2 border-gray-700">
                            <h1 className="ml-1">
                                KANADA'DA ÜNİVERSİTE & KOLEJ OKUMAK
                            </h1>
                        </h1>

                        <p className="mt-8 text-justify text-gray-700">
                            Kanada okulları, geniş bir yelpazede binlerce farklı program sunuyor. İşletme, bilişim 
                            teknolojileri, turizm ve otelcilik, sağlık hizmetleri, mühendislik ve daha pek çok alanda 
                            eğitim fırsatları bulunur. Bu çeşitlilik, her öğrencinin ilgi alanlarına ve kariyer 
                            hedeflerine uygun bir program bulmasına yardımcı olur.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            Kanada kolejlerindeki eğitim kalitesi uluslararası standartlara uygun olarak yüksektir. Öğretim görevlileri alanlarında deneyimli ve öğrencilere pratik beceriler kazandırmaya odaklıdır. Sınıf içi etkileşim, projeler, staj imkanları ve endüstriyle işbirliği gibi unsurlar, öğrencilerin teorik bilgilerini uygulamaya dönüştürmelerine yardımcı olur.
                        </p>

                        <div className="flex items-center justify-center w-full">
                            <img className="w-[80%] mt-8" src={Figure1} alt="kanadada-egitim"></img>
                        </div>

                        <p className="mt-8 text-justify text-gray-700">
                            Kanada'da kolej eğitimi için fiyatlar genellikle üniversite eğitimine kıyasla daha uygun seviyededir. Ancak, fiyatlar kolejden koleje ve seçilen programın özelliklerine göre değişiklik gösterebilir. Kolejler, genellikle esnek ödeme seçenekleri ve burs imkanları sunarak öğrencilerin mali yüklerini hafifletmeye çalışırlar.
                        </p>

                        <p className="mt-8 text-justify text-gray-700">
                            Kanada'da kolej eğitimi almak isteyen öğrenciler için pek çok seçenek bulunmaktadır. Ülkenin farklı bölgelerindeki kolejler, öğrencilere çeşitli kampüs olanakları, sosyal etkinlikler ve yaşam deneyimleri sunar. Bu da öğrencilerin kendilerini geliştirirken aynı zamanda Kanada'nın zengin kültürel ve doğal güzelliklerini keşfetmelerine imkan tanır.
                        </p>

                        <div className="flex items-center justify-center w-full">
                            <img className="w-[80%] mt-8" src={Figure2} alt="kanadada-egitim2"></img>
                        </div>

                        <p className="mt-8 text-justify text-gray-700">
                            Kanada'da kolej eğitimi hakkında daha fazla bilgi almak ve farklı kolej programlarını incelemek için web sitemizi ziyaret edebilirsiniz. Kolej fiyatları, program detayları ve başvuru süreci gibi konularla ilgili kapsamlı bilgileri bulabileceğiniz kaynaklar sunuyoruz. Kanada'da kolej eğitimiyle kariyerinizi şekillendirin ve uluslararası bir deneyim yaşayın!
                        </p>

                        <div className="flex flex-row mt-8 border-4 border-red-700 h-fit">
                            <div className="flex flex-col mx-4 my-4">
                                <h1 className="text-4xl text-gray-700 ">
                                    KANADA'DA EĞİTİM VE KARİYER DANIŞMANLIĞI
                                </h1>

                                <p className="mt-2 text-justify text-gray-700">
                                    EduPathways Canada olarak, Kanada'da eğitim ve yaşamla ilgili tüm sorularınıza yanıt bulacağınız güvenilir bir kaynak olmak en büyük önceliğimiz
                                </p>

                                <p className="mt-8 text-justify text-gray-700">
                                    Bizimle iletişime geçerek Kanada kolej programları ve Kanada devlet kolejleri hakkında ayrıntılı bilgiler edinebilir, Kanada’da üniversite ve kolej okumak için ihtiyacınız olanları öğrenebilirsiniz.
                                </p>

                                <p className="mt-8 text-justify text-gray-700">
                                    Ayrıca, Kanada'nın zengin kültürel yaşamı, doğal güzellikleri ve göç fırsatları hakkında da size önemli bilgiler sunuyoruz. Daha fazlası için hemen ön değerlendirme formunu doldurabilirsiniz.
                                </p>

                                <p className="mt-8 text-justify text-gray-700">
                                    Kanada’daki yeni yaşamınızı beraber planlayalım!
                                </p>

                                <div className="flex items-center justify-start mb-2">
                                    <button onClick={handleClickPRE} className="px-4 py-2 mt-8 ml-8 text-sm text-white bg-button-green rounded-2xl">
                                        Ön Değerlendirme Formu
                                    </button>
                                </div>
                            </div>

                            <div className="flex w-[40%] items-center justify-center">
                                <img className="w-[50%] mt-8" src={Logo} alt="Logo"></img>
                            </div>
                        </div>

                        <h1 className="mt-8 text-4xl text-red-700">
                            KANADA'DA ÜNİVERSİTE VE KOLEJLER
                        </h1>

                        <div className="flex flex-row">
                            <img className="w-[50%] mt-8 rounded-2xl grayscale object-cover" src={Figure3} alt="international-business"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">International Business University</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    International Business University, Toronto’nun kalbinde yer alan öğrenci odaklı bir üniversitedir. Okul kariyerinizle doğrudan alakalı, hızlandırılmış bir programda tamamlayabileceğiniz geniş bir yelpazede lisans ve lisansüstü programlar sunuyor.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">George Brown College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Kariyerinizi inşa etmek, tutkularınızı takip etmek ve geleceğinize yön vermek istiyorsanız, doğru eğitim almak önemlidir. George Brown College, Ontario'daki önde gelen kolejlerden biri olarak geniş program seçenekleri ve öğrencilere sunduğu benzersiz fırsatlarla öne çıkıyor. Bu yazıda, George Brown College'ın size neden en iyi eğitimi sunabileceğini ve kariyerinizde size nasıl bir avantaj sağlayabileceğini keşfedeceğiz.
                                </p>
                            </div>

                            <img className="w-[50%] h-full rounded-2xl grayscale object-cover" src={Figure4} alt="george-brown"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure5} alt="international-business"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">Toronto Metropolitan University</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Okulların tanıtımları sırasında genelde merkezi yerlerde olan okulları için 
                                    “………’in kalbinde yer alan” ibaresini sıkça kullansak da hiçbir okul bu ünvanı 
                                    Toronto Metropolitan University kadar hak etmiyor. Toronto’nun merkezi, Dundas 
                                    Square’in hemen yanında yer alan üniversite, öğrencilere dünyanın en büyük 
                                    şehirlerinden birinde okuma imkânı ve lüksünü sunuyor.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">University of Niagara Falls</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Kanada'nın University of Niagara Falls (UNF) üniversitesi Niagara Şelalesi'nin kalbinde bulunur ve mezunlarını dijital bir dünyada liderlik için hazırlayan bir akademik toplumun geliştirilmesine odaklanır. UNF, çeşitli mesleki odaklı lisans ve yüksek lisans programları sunar.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure6} alt="niagara-falls"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure7} alt="university-windsor"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">University of Windsor</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    University of Windsor, Kuzey Amerika'nın en işlek sınır kapısına komşu olan, Detroit Nehri'nin göz alıcı manzarasına sahip öğrenci odaklı bir üniversitedir. Windsor'ın konumu, Kanada'nın en önemli uluslararası odaklı kurumlarından biri olmasını sağlayan mükemmel bir noktada bulunur.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">University Canada West</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Vancouver'ın en hareketli bölgeleri olan West End ve Yaletown arasında konumlanan kampüs, öğrencilere bilgisayar laboratuvarları, inovasyon merkezi ve öğrenci dinlenme alanları sunuyor. UCW, öğrencilere öğrenme çevresini sıradanlıktan çıkararak donanımlı bir geleceğe taşıyan bir eğitim teklif ediyor!
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure8} alt="canada-west"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure9} alt="vancouver-community"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">Vancouver Community College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Vancouver Community College (VCC), dünyanın en yaşanabilir şehirlerinden biri olan Vancouver'da bulunan önemli bir eğitim kurumudur. Öğrencilere kaliteli eğitim ve iş dünyasına hazırlanmak için gereken fırsatları sunan VCC, çeşitli özellikleriyle dikkat çekmektedir.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Niagara College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Niagara College, Kanada'da eğitim almak isteyenler arasında büyük bir popülerliğe sahip olan bir kolejdir. Okul, geniş bölüm seçenekleri ve modern kampüsleri ile öğrencilere mükemmel bir eğitim deneyimi sunar. İşte Niagara College'ı diğer kolejlerden farklı kılan bazı özellikler:
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure10} alt="niagara-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure11} alt="stclair-college"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">St.Clair College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    St. Clair College'ın en belirgin özelliklerinden biri, sektörle yakın ilişkileri ve endüstri standartlarında eğitim sunmasıdır. Programlar, sektör liderleri ve profesyoneller tarafından tasarlanmıştır ve öğrencilere gerçek dünya deneyimleri kazandırmak amacıyla staj imkanları sunmaktadır. Bu sayede mezun olduktan sonra iş dünyasına hızla adapte olabilir ve kariyerinizi başarıyla ilerletebilirsiniz.</p>
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Mohawk College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Mohawk College, öğrencilere benzersiz bir deneyim sunan dinamik ve yenilikçi bir eğitim ortamı sunmaktadır. Kurumun öncelikleri arasında öğrencilerin bireysel potansiyellerini keşfetmelerini sağlamak, endüstri standartlarına uygun beceriler kazandırmak ve kapsayıcı bir öğrenme ortamı oluşturmaktır.</p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure12} alt="mohawk-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure13} alt="University-of-Toronto"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">University of Toronto</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    1827 yılında kapılarını açan UofT yüz yıldan fazladır Kanadalılara ve uluslararası öğrencilere eğitim vermeye devam ediyor. Üniversite uzun yıllardır Kanada’nın bir numaralı üniversite olarak biliniyor ve bu ünvanı korumak için kaliteli eğitimine özen gösteriyor.             
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Durham College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Durham College, 1967 yılında kurulan ve Ontario bölgesinde bulunan bir kolejdir. Durham College, öğrencilerine pratik ve teorik eğitimler sunarak, öğrencilerin iş dünyasına hazırlanmalarını sağlıyor. Durham College öğrencilerin aldıkları eğitimlerin uygulamalı olmasına oldukça özen gösteriyor, bu yüzden kolej bünyesinde alacağınız eğitimin kalitesinin yüksek olmasına dikkat ediyorlar.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure14} alt="durham-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure15} alt="St.Lawrence-college"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">St.Lawrence College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    St.Lawrence nehri yanına kurulmuş olan ve adını da bu nehirden alan St.Lawrence, Doğu Ontario’nun en prestijli okullarından biridir. Öğrenci dostu kasabalarda bulunan kampüsler okula katılan tüm uluslararası öğrenciler için büyük ölçüde kolaylıklar sağlamakta.                                
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Cambrian College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Cambrian College, Kanada’nın Ontario bölgesinde yer alan bir üniversitedir. Okul, çeşitli bölümlerde eğitim veren birçok fakülteye sahiptir. Bu bölümler arasında, mühendislik, sağlık, hukuk, işletme, sosyal bilimler ve sanat ve tasarım gibi alanlar bulunur.   
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure16} alt="Cambrian-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure17} alt="York-University"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">York University</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Toronto, Kanada'da bulunan York Üniversitesi, çeşitli öğrenci yelpazesi ve güçlü akademik programlarıyla bilinen bir devlet araştırma üniversitesidir.
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">ILAC College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Kanada’ya gelmek için araştırma yapan hemen hemen herkes ILAC’in namını duymuştur. Merkezi konumu, mesleki olarak geçerli bölümleri ve dil okulu imkanı ile Kanada’ya gelmek isteyen öğrencilerin tüm ihtiyaçlarını karşılayabiliyor.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure18} alt="ILAC-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure19} alt="Fleming-College"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">Fleming College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    1967 Yılında kurulan, o günden beri inovasyon ve gelişime önem veren Flemming College, kaliteli eğitime önem veren herkesi kampüslerine davet ediyor.
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Georgian College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Ana kampüsü Berrie şehrinde bulunan Georgian College sizi güvenli ve kaliteli bir eğitime davet ediyor. Berrie dışında 7 farklı bölgede kampüsleri olan okul, ILAC ile ortaklığa girerek Toronto merkezde bir kampüs daha açtı. Georgian College 125’in üzerinde farklı alanda eğitim imkanı sunuyor. Buna ek olarak homestay, ve kampüs içinde konaklama imkanları da mevcut.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure20} alt="Georgian-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure21} alt="Loyalist-College"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">Loyalist College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Küçük ancak bunu avantajına kullanan, verdiği eğitime ve öğrencilerine ekstra önem veren Loyalist College sizleri Ontario, Belleville kasabasına davet ediyor. Okulun en önemli özelliği öğretmen ve çalışan kadrosunun öğrenciler ile yakın temas kurarak özel ilgi gösterebiliyor olması. Ayrıca International Center ile uluslararası öğrencilerin oryantasyonunu kolaylaştırmayı hedefliyorlar.
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Fanshawe College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    Fanshawe College, London, Ontario’da yer alan ve aynı bölgede 8 farklı kampüsü olan bir kolejdir. London şehri, Toronto’ya 2 saat uzaklıkta olan ve Kanada’nın 11. büyük metropolitan şehirlerinden biri olarak bilinir. 21.000’den fazla öğrencinin 7.500’ü yabancı olan okulun şuana kadar ortalama 200.000’den fazla mezunu var.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure22} alt="Fanshawe-college"></img> 
                        </div>

                        <div className="flex flex-row mt-8">
                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure23} alt="Seneca-College"></img>

                            <div className="flex flex-col items-center justify-center ml-4">
                                <h1 className="text-xl text-gray-700">Seneca College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    1967 yılında eğitim vermeye başlayan Seneca College, Kanada’nın en köklü kolejlerinden biri olma ünvanına sahip. 160'tan fazla bölüm ve 500 farklı kariyer seçeneği ile hizmet veren okul, Kanada’nın en saygın okullarından biri olarak kabul edilmekte.
                                </p>               
                            </div>
                        </div>

                        <div className="flex flex-row mt-8">
                            <div className="flex flex-col items-center justify-center mr-4">
                                <h1 className="text-xl text-gray-700">Douglas College</h1>
                                <p className="mt-4 text-sm text-center text-gray-700">
                                    1970 yılında eğitime başlayan Douglas College, British Colombia’nın en fazla mezun veren koleji olarak biliniyor. Yılda ortalama 25.000 öğrenciye eğitim veren kolejin Vancouver'da iki farklı kampüsü var. Kampüslerin toplu taşımaya olan yakınlıkları, Douglas College’ı en ulaşılabilir kolejlerden biri haline getiriyor.
                                </p>
                            </div>

                            <img className="w-[50%] rounded-2xl grayscale object-cover" src={Figure24} alt="Douglas-college"></img> 
                        </div>

                        <h1 className="mt-12 text-4xl text-gray-700 border-l-2 border-gray-700">
                            <h1 className="ml-1">
                                KANADA'DA EĞİTİM İLE ALAKALI EN ÇOK SORULAN SORULAR
                            </h1>
                        </h1>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da Üniversite ve Kolej farkı nedir?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Üniversite akademik eğitime önem verirken, Kolejler mesleki ve uygulamalı bir eğitim verirler.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kolej mi yoksa Üniversite mi okumalıyım?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Eğer akademik yönde kariyer yapmayı planlıyorsanız Üniversite sizin için daha uygun olabilir. Ancak mesleki yönden uzmanlık kazanmak istiyorsanız Kolej eğitimini tercih edebilirsiniz.
                        </p>
                        
                        
                        <h1 className="mt-8 text-2xl text-red-700">
                            Kolejlerin ve Üniversitelerin yıllık fiyatları ne kadar?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Devlet kolejleri yıllık 15.000$-25.000$ CAD arasındadır.
                        </p>
                        <p className="text-justify text-gray-700">
                            Özel kolejler yıllık 5.000$CAD’dan başlar.
                        </p>
                        <p className="text-justify text-gray-700">
                            Üniversiteler yıllık 25.000$ CAD’in üzerindedir.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kolej eğitimi sonrası kaç yıllık çalışma izni alabilirim?
                        </h1>
                        <p className="text-justify text-gray-700">
                            1 yıllık devlet koleji eğitimi sonrası 1 yıl PGWP (Mezuniyet sonrası çalışma izni),
                        </p>
                        <p className="text-justify text-gray-700">
                            2 yıllık devlet koleji eğitimi sonrası 3 yıl PGWP’ye baş vurabilirsiniz.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            İngilizcem yeterli değilse ne yapabilirim?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Kolej ve Üniversitelerin kendi dil programlarına katılabileceğiniz gibi, özel dil okullarına katılarak da dil eğitimi alabilirsiniz.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da Okurken çalışma iznim olacak mı?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Kanada’da okuduğunuz süre boyunca part-time çalışma izniniz olur. Okul içinde bir iş bulursanız, orada çalıştığınız zamanlar part-time çalışma sürenize dahil değildir.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da okurken eşim çalışabilecek mi, çocuğum eğitim alabilecek mi?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Özel kolejlerde ve devlet kolejlerinin lisans programlarında okursanız eşinize çalışma izni verilmez. Ancak yüksek lisans programlarında ve doktora sırasında eşinize çalışma izni verilir.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada okulları iş bulmaya yardımcı oluyor mu?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Kolejlerin kariyer destek ofisleri, iş ve staj bulmanıza destek olurlar. Bunun dışında co-op olarak seçilen programlar da mezuniyet sonrası iş bulmanıza yardımcı olur.
                        </p>
                        
                        <h1 className="mt-8 text-2xl text-red-700">
                            Okul yurdunda mı kalmalı yoksa kiraya mı çıkmalı?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Eğer tek başınıza geliyorsanız okul yurtları özellikle ilk dönem için iyi bir seçenek olabilir. Aileniz ile gelecekseniz kiraya çıkmak daha uygun bir seçenek olacaktır.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Türkiye’den transfer kredisi yapabilir miyim?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Kanada'daki eğitim kurumları, yurtdışından gelen öğrencilerin daha önce aldıkları dersleri değerlendirir ve kendi programlarına uygun şekilde kabul eder. Derslerin saydırılması, kolej veya üniversitenin politikalarına ve programına bağlı olarak değişiklik gösterebilir.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da vize işlemlerini nasıl halledebilirim?
                        </h1>
                        <p className="text-justify text-gray-700">
                            İş ortağımız olan Northern Pathways ekibi ile temasa geçerek vize işlemleri ve gereklilikleri hakkında bilgi alabilir, vize başvurunuzu yapabiliriniz.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada öğrenci vizesi nasıl alınır?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Kanada'da study permit dediğimzi belge, vizenin dışında verilen ve Kanada'da eğitim alabileceğinizi gösteren bir belgedir. Bu belgeyi alabilmek için Kanada'da eğitim almak istediğiniz kurum tarafından okula kabul edilmiş olmanız gerekiyor. Sonrasında eyaletler tarafından özel olarak verilen "Attestation Letter"' alınması gerekiyor. Bu belge federal hükümetin dışında, eyaletlerin kontrolünde olan bir belgedir. Tüm belgeleriniz tamamlandıktan sonra öğrenim iznini ve vizenize başvurabilirsiniz.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da okumak için gerekenler nelerdir?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Öncelikle eğitim almak istediğinizi bölümü, bu bölümün gelecekteki kariyerinize etkisini ve yaşamak istediğiniz eyaleti seçmeniz gerekiyor. Bu seçimleri göz önünde bulundururken, eğer ki amacınız göçmenlik ise,  göçmenlik başvurusu sırasında alabileceğiniz puanları mutlaka göz önünde bulundurmalısınız.
                        </p>

                        <h1 className="mt-8 text-2xl text-red-700">
                            Kanada'da okul başvurusu nasıl yapılır?
                        </h1>
                        <p className="text-justify text-gray-700">
                            Bizimle iletişime geçerek tabii! Sizin durumunuza ve taleplerinize uygun okul seçimlerimiz ile Kanada'da olası en iyi eğitimi almanız en büyük önceliğimiz.
                        </p>
                        <p className="mt-8 text-justify text-gray-700">
                            Söz konusu Kanada'da eğitim olduğunda her sorunun cevabı bu kadar kısa ve öz olmayabilir. Tüm sorularınıza yanıt almak ve Kanada'da eğitim olanaklarını öğrenmek için ön değerlendirme formunu doldurarak bizimle iletişime geçebilirsiniz.
                        </p>

                        <div className="flex items-center justify-center mb-8">
                            <button onClick={handleClickPRE} className="py-4 mt-8 text-sm text-white px-8 bg-button-green rounded-2xl">
                                Ön Değerlendirme Formu
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default EducationCanada;