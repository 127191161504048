import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "./AppContext";
import { useSpring, animated } from "react-spring";
import { useNavigate } from 'react-router-dom';

const Summary = () => {
  const navigate = useNavigate();

  const navigateToResults = () => {
    navigate('/results');
  };

  const { calculatedValues } = useAppContext();

  //---------------------------- Education ----------------------------

  const progressEducationBarRef = useRef(null);
  const maxValueEducation = 50000;
  const valueEducation = calculatedValues.education + calculatedValues.educationInput || 0;

  const { educationValue } = useSpring({
    from: { educationValue: 0 },
    to: { educationValue: parseInt(calculatedValues.education + calculatedValues.educationInput, 10) || 0 },
    reset: false,
  });

  const [totalEducationValue, setTotalEducationValue] = useState(0);
  
  useEffect(() => {
    setTotalEducationValue(valueEducation + totalEducationValue);
  }, [valueEducation]);

  useEffect(() => {
    const progressEducationBar = progressEducationBarRef.current;
    const percentage = (valueEducation / maxValueEducation) * 100 + "%";
    progressEducationBar.style.width = percentage;

    const color =
      valueEducation === maxValueEducation
        ? "red"
        : calculateColorEducation(valueEducation, maxValueEducation);
        progressEducationBar.style.backgroundColor = color;
  }, [calculatedValues.education, valueEducation]);

  const calculateColorEducation = (valueEducation, max) => {
    const percentage = (valueEducation / max) * 100;
    const green = 255 - Math.round(percentage * 2.55);
    const red = Math.round(percentage * 2.55);
    return `rgb(${red}, ${green}, 0)`;
  };

//--------------------------------------------------------------
//---------------------------- Food ----------------------------

  const progressFoodBarRef = useRef(null);
  const maxValueFood = 1000;
  const valueFood = (calculatedValues.food + calculatedValues.food2) || 0;

  const { foodValue } = useSpring({
    from: { foodValue: 0 },
    to: { foodValue: parseInt(calculatedValues.food + calculatedValues.food2, 10) || 0 },
    reset: false,
  });

  const [totalFoodValue, setTotalFoodValue] = useState(0);

  useEffect(() => {
    setTotalFoodValue(valueFood + totalFoodValue);
  }, [valueFood]);

  useEffect(() => {
    const progressFoodBar = progressFoodBarRef.current;
    const percentage = (valueFood / maxValueFood) * 100 + "%";
    progressFoodBar.style.width = percentage;

    const color =
      valueFood === maxValueFood
        ? "red"
        : calculateColorFood(valueFood, maxValueFood);
        progressFoodBar.style.backgroundColor = color;
  }, [calculatedValues.food, valueFood]);

  const calculateColorFood = (valueFood, max) => {
    const percentage = (valueFood / max) * 100;
    const green = 255 - Math.round(percentage * 2.55);
    const red = Math.round(percentage * 2.55);
    return `rgb(${red}, ${green}, 0)`;
  };

//--------------------------------------------------------------
//---------------------------- Travel ----------------------------
  
  const progressTravelBarRef = useRef(null);
  const maxValueTravel = 1500;
  const valueTravel = (calculatedValues.travel + calculatedValues.travelAlt + calculatedValues.travelAlt2 + calculatedValues.travelInput + calculatedValues.travelInput2) || 0;

  const { travelValue } = useSpring({
    from: { travelValue: 0 },
    to: { travelValue: parseInt(calculatedValues.travel + calculatedValues.travelAlt + calculatedValues.travelAlt2 + calculatedValues.travelInput + calculatedValues.travelInput2, 10) || 0 },
    reset: false,
  });

  const [totalTravelValue, setTotalTravelValue] = useState(0);

  useEffect(() => {
    setTotalTravelValue(valueTravel + totalTravelValue);
  }, [valueTravel]);

  useEffect(() => {
    const progressTravelBar = progressTravelBarRef.current;
    const percentage = (valueTravel / maxValueTravel) * 100 + "%";
    progressTravelBar.style.width = percentage;

    const color =
      valueTravel === maxValueTravel
        ? "red"
        : calculateColorTravel(valueTravel, maxValueTravel);
        progressTravelBar.style.backgroundColor = color;
  }, [calculatedValues.travel, valueTravel]);

  const calculateColorTravel = (valueTravel, max) => {
    const percentage = (valueTravel / max) * 100;
    const green = 255 - Math.round(percentage * 2.55);
    const red = Math.round(percentage * 2.55);
    return `rgb(${red}, ${green}, 0)`;
  };


//--------------------------------------------------------------
//---------------------------- Personal ----------------------------

  const progressPersonalBarRef = useRef(null);
  const maxValuePersonal = 1500;
  const valuePersonal = (calculatedValues.personal + calculatedValues.personalAlt + calculatedValues.personalAlt2) || 0;

  const { personalValue } = useSpring({
    from: { personalValue: 0 },
    to: { personalValue: parseInt(calculatedValues.personal + calculatedValues.personalAlt + calculatedValues.personalAlt2, 10) || 0 },
    reset: false,
  });

  const [totalPersonalValue, setTotalPersonalValue] = useState(0);

  useEffect(() => {
    setTotalPersonalValue(valuePersonal + totalPersonalValue);
  }, [valuePersonal]);

  useEffect(() => {
    const progressPersonalBar = progressPersonalBarRef.current;
    const percentage = (valuePersonal / maxValuePersonal) * 100 + "%";
    progressPersonalBar.style.width = percentage;

    const color =
      valuePersonal === maxValuePersonal
        ? "red"
        : calculateColorPersonal(valuePersonal, maxValuePersonal);
        progressPersonalBar.style.backgroundColor = color;
  }, [calculatedValues.personal, valuePersonal]);

  const calculateColorPersonal = (valuePersonal, max) => {
    const percentage = (valuePersonal / max) * 100;
    const green = 255 - Math.round(percentage * 2.55);
    const red = Math.round(percentage * 2.55);
    return `rgb(${red}, ${green}, 0)`;
  };

//--------------------------------------------------------------
//---------------------------- Housing ----------------------------

const progressHousingBarRef = useRef(null);
const maxValueHousing = 3000;
const valueHousing = (calculatedValues.housing + calculatedValues.housingAlt + calculatedValues.housingAlt2 + calculatedValues.housingAlt3 + calculatedValues.housingInput) || 0;

const { housingValue } = useSpring({
  from: { housingValue: 0 },
  to: { housingValue: parseInt(calculatedValues.housing + calculatedValues.housingAlt + calculatedValues.housingAlt2 + calculatedValues.housingAlt3 + calculatedValues.housingInput, 10) || 0 },
  reset: false,
});

const [totalHousingValue, setTotalHousingValue] = useState(0);

useEffect(() => {
  setTotalHousingValue(valueHousing + totalHousingValue);
}, [valueHousing]);

useEffect(() => {
  const progressHousingBar = progressHousingBarRef.current;
  const percentage = (valueHousing/ maxValueHousing) * 100 + "%";
  progressHousingBar.style.width = percentage;

  const color =
    valueHousing === maxValueHousing
      ? "red"
      : calculateColorHousing(valueHousing, maxValueHousing);
      progressHousingBar.style.backgroundColor = color;
}, [calculatedValues.housing, valueHousing]);

const calculateColorHousing = (valueHousing, max) => {
  const percentage = (valueHousing / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Income ----------------------------
const progressIncomeBarRef = useRef(null);
const maxValueIncome = 5000;
const valueIncome =  (calculatedValues.incomeAlt + (calculatedValues.incomeAlt2*calculatedValues.incomeAlt4)*4 + calculatedValues.incomeAlt5 + calculatedValues.incomeAlt6) || 0;

const { incomeValue } = useSpring({
  from: { incomeValue: 0 },
  to: { incomeValue: parseInt(calculatedValues.incomeAlt + (calculatedValues.incomeAlt2*calculatedValues.incomeAlt4)*4 + calculatedValues.incomeAlt5 + calculatedValues.incomeAlt6, 10) || 0 },
  reset: false,
});

const [totalIncomeValue, setTotalIncomeValue] = useState(0);

useEffect(() => {
  setTotalIncomeValue(valueIncome + totalIncomeValue);
}, [valueIncome]);

useEffect(() => {
  const progressIncomeBar = progressIncomeBarRef.current;
  const percentage = (valueIncome/ maxValueIncome) * 100 + "%";
  progressIncomeBar.style.width = percentage;

  const color =
    valueIncome === maxValueIncome
      ? "green"
      : calculateColorIncome(valueIncome, maxValueIncome);
      progressIncomeBar.style.backgroundColor = color;
}, [valueIncome]); //

const calculateColorIncome = (valueIncome, max) => {
  const percentage = (valueIncome / max) * 100;
  const red = 255 - Math.round(percentage * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------
//---------------------------- Total ----------------------------
const progressTotalBarRef = useRef(null);
const maxValueTotal = 50000;
const valueTotal = valueEducation + valueFood + valueTravel + valuePersonal + valueHousing || 0;

const { totalValue } = useSpring({
  from: { totalValue: 0 },
  to: { totalValue: parseInt(valueTotal, 10) || 0 },
  reset: false,
});

const [totalTotalValue, setTotalTotalValue] = useState(0);

useEffect(() => {
  setTotalTotalValue(valueTotal + totalTotalValue);
}, [valueTotal]);

useEffect(() => {
  const progressTotalBar = progressTotalBarRef.current;
  const percentage = (valueTotal/ maxValueTotal) * 100 + "%";
  progressTotalBar.style.width = percentage;

  const color =
    valueTotal === maxValueTotal
      ? "red"
      : calculateColorTotal(valueTotal, maxValueTotal);
      progressTotalBar.style.backgroundColor = color;
}, [valueTotal]); //

const calculateColorTotal = (valueTotal, max) => {
  const percentage = (valueTotal / max) * 100;
  const green = 255 - Math.round(percentage * 2.55);
  const red = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};
//--------------------------------------------------------------
//---------------------------- Total Income ----------------------------

const progressIncomeTotalBarRef = useRef(null);

useEffect(() => {
  const progressIncomeTotalBar = progressIncomeTotalBarRef.current;
  const percentage = (valueIncome/ maxValueIncome) * 100 + "%";
  progressIncomeTotalBar.style.width = percentage;

  const color =
    valueIncome === maxValueIncome
      ? "green"
      : calculateColorIncomeTotal(valueIncome, maxValueIncome);
      progressIncomeTotalBar.style.backgroundColor = color;
}, [valueIncome]); //

const calculateColorIncomeTotal = (valueIncome, max) => {
  const percentage = (valueIncome / max) * 100;
  const red = 255 - Math.round(percentage * 2.55);
  const green = Math.round(percentage * 2.55);
  return `rgb(${red}, ${green}, 0)`;
};

//--------------------------------------------------------------


  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 150;
      setIsVisible(window.scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="summary-container"
      className={`fixed top-0 right-0 w-[19%] min-h-[89.25%] p-8 flex flex-col bg-white rounded-lg shadow-lg mx-auto`}
      style={{
        opacity: isVisible ? 1 : 0,
        right: isVisible ? "0" : "-100px",
        transition: "opacity 0.5s ease-in-out, right 0.5s ease-in-out",
        pointerEvents: isVisible ? "auto" : "none",
      }}
    >
      <div className="flex flex-col mx-auto">
        <h1 className="    sm:text-[12px] md:text-[14px] lg:text-[16px]">
          Finansal Özet
        </h1>
        <hr className="my-1 border-b-2 border-gray-500 " />

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Eğitim
          </h1>

          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {educationValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressEducationBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Konaklama
          </h1>

          <p className="sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {housingValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressHousingBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Gıda
          </h1>


          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {foodValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressFoodBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Ulaşım
          </h1>

          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {travelValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressTravelBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Kişisel Harcamalar
          </h1>

          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {personalValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressPersonalBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col mx-auto pb-[10px]">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Gelirler
          </h1>


          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {incomeValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressIncomeBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <hr className="my-1 border-b-2 border-gray-500 " />

        <div className="flex flex-col pt-[14px] mx-auto">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Toplam Giderler
          </h1>

          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {totalValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressTotalBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <div className="flex flex-col pt-[14px] mx-auto">
          <h1 className="    sm:text-[12px] md:text-[12px] lg:text-[14px]">
            Toplam Gelirler
          </h1>

          <p className="    sm:text-[10px] md:text-[10px] lg:text-[12px]">
            <animated.p className="">
              {incomeValue.interpolate((val) => {
                return val >= 1000
                  ? `$${(val / 1000).toFixed(3).replace('.', ',')}CAD`
                  : `$${Math.floor(val)}CAD`;
              })}
            </animated.p>
            <div className="flex pt-1 mx-auto progress-bar-container">
              <div
                className="progress-bar"
                ref={progressIncomeTotalBarRef}
                style={{
                  height: "10px",
                  transition:
                    "width 1s ease-out, background-color 0.5s ease-out",
                  borderRadius: "5px",
                }}
              ></div>
            </div>
          </p>
        </div>

        <hr className="my-5 border-b-2 border-gray-500 " />

        <div className="flex justify-center sm:flex-row">
          <button
            onClick={() => navigateToResults()}
            className="xl:px-16 xl:py-2 lg:px-12 lg:py-2 sm:px-8 sm:py-1   text-red-700 transition-colors duration-300 bg-white border-2 rounded-md sm:text-[12px] md:text-[12px] lg:text-[14px]    border-pathway-red hover:bg-red-700 hover:text-white"
          >
            Bitir
          </button>
        </div>
      </div>
    </div>
  );
};

export default Summary;
