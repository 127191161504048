import './App.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import Expenses from './Expenses';
import Results from './Results';
import Life from './lifeCanada';
import LifeExpense from './lifeExpenseCanada';
import Education from './educationCanada';
import PGWP from './pgwpCanada';

import { AppProvider } from './AppContext';
import ReactGA from 'react-ga4';


function App() {
  ReactGA.initialize([
    {
      trackingId: "G-VBNV0HYT12",
    },
  ]);

  ReactGA.send({ hitType: "pageview", page: "/" });


 return (
  <>
    <Header />
    <AppProvider>
      <Routes>
        <Route path="/" element={<Expenses />} />
        <Route path="/results" element={<Results />}></Route>
        <Route path="/kanadadayasam" element={<Life />}></Route>
        <Route path="/kanada-da-yasam-masraflari" element={<LifeExpense />}></Route>
        <Route path="/kanada-da-egitim" element={<Education />}></Route>
        <Route path="/kanada-da-mezun-olduktan-sonra-calismak-pgwp" element={<PGWP />}></Route>
      </Routes>
    </AppProvider>
    <Footer />
  </>
 );
}

export default App;